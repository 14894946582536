import { useMutation, useQuery } from '@tanstack/react-query'

import { Flex, Space, Tooltip } from 'antd'

import invoiceApi from 'api/invoiceApi'

import { setAppSpinning, showNotification } from 'app/appSlice'

import dayjs from 'dayjs'

import { goodsQueries } from 'features/Category/screens/GoodsScreen/queries'

import IssueInvoicesFromPosModal from 'features/Invoice/components/modals/ModalIssueInvoicesFromPos'

import ModalListInvoiceFromPOS from 'features/Invoice/components/modals/ModalListInvoiceFromPOS'

import ModalSendFromPOS from 'features/Invoice/components/modals/SendFromPOS'

import ModalSendInvoiceMail from 'features/Invoice/components/modals/SendInvoiceMail'

import {
  resetModals,
  setModalsState,
  setSelectedInvoice,
  setSelectedInvoices,
  useInvoiceSelector,
} from 'features/Invoice/invoiceSlice'

import ModalConvertPaper from 'features/Search/components/ModalConvertPaper'

import AppDnD from 'general/components/AppDragAndDrop/AppDnD'

import CustomAntButton from 'general/components/Button/CustomAntButton'

import ContentContainer from 'general/components/ContentContainer'

import CustomDataTable from 'general/components/CustomDataTable'

import FilterPopover from 'general/components/CustomPopover/FilterPopover'

import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'

import { IconAction } from 'general/components/GroupButton/styles'

import TableAction from 'general/components/GroupButton/TableAction'

import CancelInvoiceModal from 'general/components/Modal/CancelInvoiceModal'

import ExplanationInvoiceModal from 'general/components/Modal/ExplanationInvoiceModal'

import IssueInvoiceFromPos from 'general/components/Modal/IsssueInvoiceFromPosModal'

import ModalConfirm from 'general/components/Modal/ModalConfirm'

import ModalSelectAdjustInvoiceMethod from 'general/components/Modal/ModalSelectAdjustInvoiceMethod'

import ModalSelectCancellationMethod from 'general/components/Modal/ModalSelectCancellationMethod'

import { INVOICE_STATUS, INVOICE_TYPE } from 'general/constants/AppConstants'

import PreferenceKeys from 'general/constants/PreferenceKeys'

import SelectOptions from 'general/constants/SelectOptions'

import InvoiceHelper from 'general/helpers/InvoiceHelper'

import ToastHelper from 'general/helpers/ToastHelper'

import Utils from 'general/utils/Utils'

import useGetSerials from 'hooks/Queries/useGetSerials'

import useQueryCompanyUnit from 'hooks/Queries/useQueryCompanyUnit'

import useAppDnD from 'hooks/useAppDnD'

import useFilter from 'hooks/useFilter'

import useFireSwal from 'hooks/useFireSwal'

import { useAppDispatch, useAppSelector } from 'hooks/useRedux'

import useRouter from 'hooks/useRouter'

import useSearchDebounce from 'hooks/useSearchDebounce'

import _ from 'lodash'

import { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  useMutationCancelInvoice,
  useMutationDownloadInvoice,
} from '../ListInvoice/queries'

import {
  listInvoiceFromPosQueries,
  useMutationBulkInvoicePaidStatus,
  useMutationDeleteInvoice,
  useMutationDownloadInvoice as useMutationDownloadInvoiceFromPos,
  useMutationInvoicePaidStatus,
  useMutationSendExplanationAnnounce,
  useMutationViewPdf,
} from './queries'

import ModalUploadInvoiceExcel from 'general/components/Modal/ModalUploadInvoiceExcel'

import { Spinner } from 'react-bootstrap'
import { CloneMultiInvoiceModal } from 'general/components/Modal/CloneMultiInvoiceModal'

export default function ListInvoiceFromPOS() {
  const { t } = useTranslation()

  const { navigate } = useRouter()

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')

  const [filterParams, setFilterParams] = useFilter(
    'listInvoiceFromPOS',

    'gFilterListInvoiceFromPOS',
  )

  const dispatch = useAppDispatch()

  const { fireConfirm } = useFireSwal()

  const { activeSigningMethod } = useAppSelector(
    (state) => state.system.company,
  )

  const { modalStates, selectedInvoice, selectedInvoices, toggledClearRows } =
    useInvoiceSelector()

  const [goodsFilterParams] = useState({
    limit: 50,

    page: 0,

    status: 'ACTIVE',

    q: '',
  })

  const [isInvoiceAdjust, setIsInvoiceAdjust] = useState(false)

  const [hsmSignModalInfo, setHSMSignModalInfo] = useState({
    show: false,

    invoiceIds: [],
  })
  const [cloneMultiInvoiceModal, setCloneMultiInvoiceModal] = useState({
    show: false,
    invoiceId: null,
  })

  // ============ QUERIES ============

  const { data: serials } = useGetSerials('POS')

  const { mutate: handleViewInvoice } = useMutationViewPdf()

  const { mutate: updateInvoicePaidStatus, isPending: isUpdatingPaidStatus } =
    useMutationInvoicePaidStatus()

  const {
    mutate: updateBulkInvoicePaidStatus,

    isPending: isUpdatingBulkPaidStatus,
  } = useMutationBulkInvoicePaidStatus()

  const { mutate: handleDelete } = useMutationDeleteInvoice()

  const { mutate: downloadInvoices } = useMutationDownloadInvoice()

  const { handleDownloadPdf, handleDownloadXml } =
    useMutationDownloadInvoiceFromPos()

  const { data: companyUnitList } = useQueryCompanyUnit({
    limit: 50,

    page: 1,
  })

  const { data: goodsOption } = useQuery({
    ...goodsQueries.listOfGoods(goodsFilterParams),

    select: (res) =>
      _.concat({ value: null, label: 'Tất cả hàng hóa' }, res?.goods).map(
        (v) => ({
          value: v.name ?? v.value,

          label: v.label ?? v.name,
        }),
      ),
  })

  const { mutate: sendExplanationAnnounceToCustomer } =
    useMutationSendExplanationAnnounce()

  const {
    data: listInvoiceData,

    isLoading: isGettingListData,

    isRefetching: isRefetchingListData,

    refetch: refetchListInvoice,
  } = useQuery(listInvoiceFromPosQueries.listInvoice(filterParams))

  const { mutate: handleConvertToPaper } = useMutation({
    mutationKey: [listInvoiceFromPosQueries.base, { action: 'convertToPaper' }],

    mutationFn: (params) => invoiceApi.convertPaper(params),

    onSuccess: (res) => {
      if (!res) return

      ToastHelper.showSuccess('Chuyển thành hóa đơn giấy thành công')

      Utils.openFileInNewTab({
        blobData: res,

        fileName: 'Hóa đơn chuyển đổi.pdf',

        fileType: 'application/pdf',
      })
    },
  })

  const { mutate: cancelInvoice } = useMutationCancelInvoice()

  // ============ HANDLERS ============

  const issueInvoiceFromPosHandle = async ({ sendEmailHandle }) => {
    dispatch(setAppSpinning(false))

    try {
      let res = await invoiceApi.issueInvoicesMTT({
        invoiceIds: [selectedInvoice.invoiceId],
      })

      dispatch(setAppSpinning(false))

      if (res?.data) res = res.data

      if (
        !!res?.successIssueInvoices?.find(
          ({ invoiceId }) => invoiceId == selectedInvoice.invoiceId,
        )
      ) {
        ToastHelper.showSuccess('Phát hành hóa đơn thành công')

        //  sendEmail

        await sendEmailHandle()

        // reload page

        refetchListInvoice()

        return
      }

      // Phát hành lại thất bại

      ToastHelper.showError(res?.errorIssueInvoices?.at(0)?.reason)

      if (
        errorIssueInvoices?.at(0)?.reason !=
        'Ngày hóa đơn nhỏ hơn ngày hóa đơn được phát hành gần nhất. Vui lòng kiểm tra lại.'
      ) {
        refetchListInvoice()
      }
    } catch (e) {
      console.log(e)

      dispatch(setAppSpinning(false))
    }
  }

  const handleResendTaxInvoices = () => {
    if (!Array.isArray(selectedInvoices) || !selectedInvoices?.length) return

    let resendInvoiceIds = selectedInvoices

      .filter(({ sendTaxStatus }) => sendTaxStatus == 'SEND_ERROR')

      .map(({ invoiceId }) => invoiceId)

    if (!resendInvoiceIds.length) {
      ToastHelper.showError('Chưa có hóa đơn MTT để gửi lại CQT')

      return
    }

    if (activeSigningMethod == 'HSM') {
      setHSMSignModalInfo({ show: true, invoiceIds: resendInvoiceIds })
    } else {
      let accessToken = localStorage.getItem(PreferenceKeys.accessToken)

      let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},summaryInvoiceSignedBySeller,`

      urlSchema =
        urlSchema +
        resendInvoiceIds

          ?.map((invoiceId, index, arr) =>
            index + 1 == arr.length ? invoiceId : invoiceId + ';',
          )

          ?.join('')

      Utils.openToolSignSummaryInvoice({ url: urlSchema, accessToken })
    }
  }

  const handleHsmSignSummaryInvoice = async () => {
    dispatch(setAppSpinning(true))

    try {
      let invoiceIds = hsmSignModalInfo.invoiceIds

      await invoiceApi.hsmSignSummaryInvoice({ invoiceIds })

      dispatch(setAppSpinning(false))

      ToastHelper.showSuccess('Gửi dữ liệu MTT đến CQT thành công')
    } catch (e) {
      dispatch(setAppSpinning(false))

      console.log(e)
    } finally {
      refetchListInvoice()
    }
  }

  const filterDefault = useMemo(() => {
    /** @type {FilterDefaultValueProp[]} */

    const arr = [
      {
        alias: 'Serial',

        label: 'Ký hiệu',

        formName: 'serials',

        value: null,

        options: serials,

        multiselect: true,

        colSpan: 'FULL',
      },

      {
        alias: 'Hóa đơn: Từ',

        label: ['Hóa đơn từ số', 'Đến số'],

        separator: 'đến',

        formName: ['fromNumberInvoice', 'toNumberInvoice'],

        value: [null, null],

        restrict: {
          type: 'number',

          min: 1,

          max: 99999999,
        },
      },

      {
        alias: 'Trạng thái HĐ',

        label: 'Trạng thái phát hành',

        formName: 'issueStatus',

        value: null,

        options: SelectOptions.issueStatus,
      },

      {
        alias: 'Loại HĐ',

        label: 'Loại hóa đơn',

        formName: 'types',

        value: null,

        options: SelectOptions.invoiceType,
      },

      {
        alias: 'Trạng thái gửi HĐ',

        label: 'Trạng thái gửi hóa đơn',

        formName: 'isSent',

        value: null,

        options: SelectOptions.isSent,
      },

      {
        alias: 'Trạng thái gửi CQT',

        label: 'Trạng thái gửi CQT',

        formName: 'sendTaxStatus',

        value: null,

        options: SelectOptions.sendTaxStatus,
      },

      {
        alias: 'Hàng hóa',

        label: 'Tên hàng hóa',

        formName: 'goodsName',

        options: goodsOption,

        value: null,

        colSpan: 'FULL',
      },
    ]

    return arr
  }, [goodsOption, serials])

  const columns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<InvoiceFromPos>[]} */

    const arr = [
      {
        id: 'companyUnitId',

        name: 'Đơn vị',

        center: true,

        hidden: true,

        selector: (row) =>
          companyUnitList.find(
            (company) => company.companyUnitId === row.companyUnitId,
          )?.companyName ?? '',

        format: (row) => {
          const text =
            companyUnitList.find(
              (company) => company.companyUnitId === row.companyUnitId,
            )?.companyName ?? ''

          return (
            <Tooltip destroyTooltipOnHide title={text} placement={'topLeft'}>
              <span>{text}</span>
            </Tooltip>
          )
        },
      },

      {
        id: 'serial',

        name: 'Ký hiệu',

        center: true,

        selector: (row) => row.serial,
      },

      {
        id: 'date',

        name: 'Ngày HĐ',

        center: true,

        format: (row) => dayjs(row.date).format('L'),

        selector: (row) => row.date,
      },

      {
        id: 'lookupCode',

        name: 'Mã tra cứu',

        center: true,

        hidden: true,

        selector: (row) => row.lookupCode,
      },

      {
        id: 'invoiceCode',

        name: 'Mã hóa đơn',

        center: true,

        hidden: true,

        selector: (row) => row.invoiceCode,
      },

      {
        id: 'no',

        name: 'Số HĐ',

        center: true,

        selector: (row) => row.no,
      },

      {
        id: 'taxAuthorityCode',

        name: 'Mã CQT',

        center: true,

        minWidth: '150px',

        grow: 2,

        selector: (row) => row.taxAuthorityCode,
      },

      {
        id: 'customerCompanyName',

        name: 'Khách hàng',

        center: true,

        hidden: true,

        selector: (row) => row.customerCompanyName,

        format: (row) => (
          <Tooltip
            destroyTooltipOnHide
            title={row?.customerCompanyName}
            placement={'topLeft'}
          >
            <span>{row.customerCompanyName}</span>
          </Tooltip>
        ),
      },

      {
        id: 'customerTaxCode',

        name: 'Mã số thuế',

        hidden: true,

        center: true,

        minWidth: '150px',

        selector: (row) => row.customerTaxCode,
      },

      {
        id: 'customerFullAddress',

        name: 'Địa chỉ',

        hidden: true,

        center: true,

        selector: (row) => row.customerFullAddress,

        format: (row) => (
          <Tooltip
            destroyTooltipOnHide
            title={row?.customerFullAddress}
            placement={'topLeft'}
          >
            <span>{row.customerFullAddress}</span>
          </Tooltip>
        ),
      },

      {
        id: 'customerName',

        name: 'Người mua hàng',

        hidden: true,

        center: true,

        selector: (row) => row.customerName,
      },

      {
        id: 'customerEmail',

        name: 'Email',

        center: true,

        hidden: true,

        selector: (row) => row.customerEmail,
      },

      {
        id: 'customerPhone',

        name: 'SĐT',

        center: true,

        hidden: true,

        selector: (row) => row.customerPhone,
      },

      {
        id: 'customerPersonalIdentificationNumber',

        name: 'CCCD/CMND',

        center: true,

        hidden: true,

        selector: (row) => row.customerPersonalIdentificationNumber,
      },

      {
        id: 'customerAddress',

        name: 'Hình thức thanh toán',

        center: true,

        hidden: true,

        format: (row) => InvoiceHelper.renderPaymentMethod(row.paymentMethod),

        selector: (row) => row.paymentMethod,
      },

      {
        id: 'customerAccountNumber',

        name: 'TK ngân hàng',

        center: true,

        hidden: true,

        selector: (row) => row.customerAccountNumber,
      },

      {
        id: 'customerBankName',

        name: 'Tên ngân hàng',

        center: true,

        hidden: true,

        format: (row) => row.customerBankName,
      },

      {
        id: 'isPayment',

        name: 'Thanh toán',

        center: true,

        minWidth: '150px',

        allowOverflow: true,

        format: (row) => InvoiceHelper.renderIsPaid(row.isPayment),

        selector: (row) => row.isPayment,
      },

      {
        id: 'currency',

        name: 'Loại tiền',

        center: true,

        hidden: true,

        selector: (row) => row.currency,
      },

      {
        id: 'exchangeRate',

        name: 'Tỷ giá',

        center: true,

        style: {
          justifyContent: 'flex-end',
        },

        hidden: true,

        selector: (row) => row.exchangeRate,
      },

      {
        id: 'totalAfterTax',

        name: 'Tổng tiền',

        center: true,

        style: {
          justifyContent: 'flex-end',
        },

        hidden: true,

        format: (row) => Utils.formatNumber(row.totalAfterTax),

        selector: (row) => row.totalAfterTax,
      },

      {
        id: 'type',

        name: 'Trạng thái HĐ',

        center: true,

        minWidth: '150px',

        format: (row) => {
          const { color, text } = InvoiceHelper.renderInvoiceType(row?.type)

          return <div className={`badge badge-light-${color}`}>{text}</div>
        },

        selector: (row) => row.type,
      },

      {
        id: 'issueStatus',

        name: 'Trạng thái phát hành',

        minWidth: '150px',

        center: true,

        format: (row) => {
          const { color, text } = InvoiceHelper.renderIssueStatus(
            row.issueStatus,
          )

          return <div className={`badge badge-light-${color}`}>{text}</div>
        },

        selector: (row) => row.issueStatus,
      },

      {
        id: 'sendTaxStatus',

        name: 'Trạng thái gửi CQT',

        minWidth: '150px',

        allowOverflow: true,

        center: true,

        format: (row) => {
          const { color, text } = InvoiceHelper.renderSendTaxStatus(
            row.sendTaxStatus,
          )

          return <div className={`badge badge-light-${color}`}>{text}</div>
        },

        selector: (row) => row.sendTaxStatus,
      },

      {
        id: 'isSent',

        name: 'Gửi mail',

        center: true,

        cell: (row) => (
          <IconAction
            className={Utils.cn(
              'fa-duotone',

              row.isSent
                ? 'fa-circle-check'
                : 'fa-do-not-enter text-light-dark',
            )}
            style={
              row.isSent
                ? {
                    '--fa-primary-color': '#fff',

                    '--fa-secondary-color': '#50cd89',

                    '--fa-secondary-opacity': 1,
                  }
                : {}
            }
          />
        ),
      },

      {
        id: 'customerSignName',

        name: 'Người mua ký',

        center: true,

        hidden: true,

        selector: (row) => row.customerName,
      },

      {
        id: 'licensePlate',

        name: 'Biển số xe',

        center: true,

        hidden: true,

        selector: (row) => row.licensePlate,
      },

      {
        id: 'customerCode',

        name: 'Mã khách hàng',

        center: true,

        hidden: true,

        selector: (row) => row.customerCode,
      },

      {
        id: 'arrivalDate',

        name: 'Ngày đến',

        center: true,

        hidden: true,

        selector: (row) => row.arrivalDate,
      },

      {
        name: 'Hành động',

        disabled: true,

        center: true,

        compact: true,

        style: {
          justifyContent: 'flex-end',
        },

        grow: 1.75,

        minWidth: '200px',

        cell: (row) => {
          return (
            <div>
              <TableAction
                titleActionText="Xem"
                icon={<IconAction className="fa-light fa-eye" />}
                onClick={() => {
                  handleViewInvoice(row.invoiceId)
                }}
              />

              {/* Phát hành hóa đơn */}

              {[
                INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,

                INVOICE_STATUS.SEND_ERROR,

                INVOICE_STATUS.SIGN_ERROR,
              ].includes(row.issueStatus) &&
              row.type !== INVOICE_TYPE.DELETED ? (
                <TableAction
                  titleActionText={'Phát hành hoá đơn'}
                  icon={<IconAction className="fa-light fa-paper-plane" />}
                  onClick={() => {
                    dispatch(setSelectedInvoice(row))

                    dispatch(
                      setModalsState({
                        modalName: 'issueInvoiceFromPos',

                        status: true,
                      }),
                    )
                  }}
                />
              ) : null}

              {/* Cập nhật hóa đơn */}

              {[INVOICE_STATUS.NOT_SENT_TO_AUTHORITY].includes(
                row.issueStatus,
              ) && row.type !== INVOICE_TYPE.DELETED ? (
                <TableAction
                  titleActionText={t('Edit')}
                  icon={<IconAction className="fa-light fa-pen-to-square" />}
                  onClick={() => {
                    navigate(
                      `/hoa-don-dau-ra/hoa-don/may-tinh-tien/cap-nhat/${row?.invoiceId}`,
                    )
                  }}
                />
              ) : null}

              {/* Xoá hóa đơn */}

              {[
                INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,

                INVOICE_STATUS.SIGN_ERROR,

                INVOICE_STATUS.SEND_ERROR,
              ].includes(row.issueStatus) &&
              row.type !== INVOICE_TYPE.DELETED ? (
                <TableAction
                  titleActionText="Xoá"
                  icon={<IconAction className="fa-light fa-trash" />}
                  onClick={async () => {
                    const result = await fireConfirm(
                      'Xác nhận xóa hóa đơn',

                      'Bạn có chắc chắn muốn xóa hóa đơn này?',
                    )

                    if (!result.value) return false

                    handleDelete([row?.invoiceId])
                  }}
                />
              ) : null}

              {/* Gửi hóa đơn cho khách hàng */}

              <TableAction
                titleActionText="Gửi hóa đơn cho KH"
                icon={<IconAction className="fa-light fa-envelope" />}
                onClick={() => {
                  dispatch(setSelectedInvoice(row))

                  dispatch(
                    setModalsState({
                      modalName: 'sendDraftInvoice',

                      status: true,
                    }),
                  )
                }}
              />

              {/* More actions */}

              <TableAction
                type="dropdown"
                items={[
                  row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                    row?.type !== INVOICE_TYPE.DELETED && {
                      label: 'Tải hóa đơn PDF',

                      icon: (
                        <IconAction className="fa-light fa-file-download" />
                      ),

                      onClick: () => handleDownloadPdf(row?.invoiceId),
                    },

                  row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                    row?.type !== INVOICE_TYPE.DELETED && {
                      label: 'Tải hóa đơn XML',

                      icon: <IconAction className="fa-light fa-file-code" />,

                      onClick: () => handleDownloadXml(row?.xmlString),
                    },

                  // Nhân bản hóa đơn

                  {
                    label: 'Nhân bản hóa đơn',

                    icon: <IconAction className="fa-light fa-clone" />,

                    onClick: () => {
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/may-tinh-tien/them-moi/${row?.invoiceId}`,
                      )
                    },
                  },
                  {
                    label: 'Nhân bản hóa đơn hàng loạt',
                    icon: <IconAction className="fa-light fa-copy" />,
                    onClick: () => {
                      dispatch(
                        setModalsState({
                          modalName: 'cloneMultiInvoiceModal',
                          status: true,
                        }),
                      )
                      setCloneMultiInvoiceModal({
                        show: true,
                        invoiceId: row?.invoiceId,
                      })
                    },
                  },
                  // Gửi thông báo giải trinh

                  row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                    row?.type === 0 && {
                      label: 'Gửi thông báo giải trình cho KH',

                      icon: <IconAction className="fa-light fa-envelope" />,

                      onClick: () => {
                        if (![0, 4].includes(row?.errAnnouceType)) {
                          let describeText = `Hóa đơn <${row.serial} - ${
                            row.no
                          }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là <${InvoiceHelper.errAnnouceTypeToVnese(
                            row.errAnnouceType,
                          )}>. Bạn không được phép gửi thông báo giải trình sai sót cho hóa đơn này.`

                          dispatch(showNotification({ describeText }))

                          return
                        }

                        // Trường hợp chưa lập tbss

                        if (row.errAnnouceType == 0) {
                          // đã lập hóa đơn thay thế, điều chỉnh

                          if (row.Related?.at(0)) {
                            let relatedType = row.Related.at(0).type

                            if (![1, 2].includes(relatedType)) {
                              ToastHelper.showError(
                                'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                              )

                              return
                            }

                            let relatedTypeVNese =
                              relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'

                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                              row.Related.serial
                            } - ${
                              row.Related.no ?? 'Chưa câp số'
                            }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`

                            dispatch(showNotification({ describeText }))

                            return
                          }

                          // kiểm tra xem có lập biên bản hủy hay điều chỉnh không

                          if (row.reportType) {
                            let reportTypeVnese =
                              row.reportType == 'CANCEL' ? 'hủy' : 'điều chỉnh'

                            let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản ${reportTypeVnese}. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${reportTypeVnese}).`

                            dispatch(showNotification({ describeText }))

                            return
                          }
                        }

                        dispatch(setSelectedInvoice(row))

                        dispatch(
                          setModalsState({
                            modalName: 'explanation',

                            status: true,
                          }),
                        )
                      },
                    },

                  {
                    type: 'divider',
                  },

                  (row?.type === 0 || row?.type === 5) &&
                    row?.issueStatus ===
                      INVOICE_STATUS.AUTHORITY_CODE_GIVEN && {
                      label: 'Điều chỉnh hoá đơn',

                      icon: (
                        <IconAction className="fa-light fa-pen-to-square" />
                      ),

                      onClick: () => {
                        // if (isCreateAdjustedInvoice) {

                        if (![0, 2, 4].includes(row.errAnnouceType)) {
                          let describeText

                          const errAnnouceTypeText =
                            InvoiceHelper.errAnnouceTypeToVnese(
                              row.errAnnouceType,
                            )

                          console.log(errAnnouceTypeText)

                          if ([3, 5].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${errAnnouceTypeText}. Bạn không được phép điều chỉnh hóa đơn này.`

                            dispatch(showNotification({ describeText }))

                            return
                          }

                          if ([2, 6].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${row.no}> đã lập thông báo sai sót với hình thức xử lý là ${errAnnouceTypeText} nhưng chưa gửi CQT. Bạn cần hoàn thành TBSS này.`

                            dispatch(showNotification({ describeText }))

                            return
                          }
                        }

                        // đã lập hóa đơn thay thế, điều chỉnh

                        if (row.Related?.at(0)) {
                          let relatedType = row.Related.at(0).type

                          if (![1, 2].includes(relatedType)) {
                            ToastHelper.showError(
                              'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                            )

                            return
                          }

                          if (relatedType == 1) {
                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn Thay thế số <${
                              row.Related.serial
                            } - ${
                              row.Related.no ?? 'Chưa cấp số'
                            }> . Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (Thay thế).`

                            dispatch(showNotification({ describeText }))

                            return
                          }

                          // show modal confirm

                          dispatch(setSelectedInvoice(row))

                          setIsInvoiceAdjust(true)

                          return
                        }

                        // Trường hợp chưa lập tbss

                        if (row.errAnnouceType == 0) {
                          // kiểm tra xem có lập biên bản hủy không

                          if (row.reportType == 'CANCEL') {
                            let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản hủy. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (hủy).`

                            dispatch(showNotification({ describeText }))

                            return
                          }
                        }

                        dispatch(setSelectedInvoice(row))

                        dispatch(
                          setModalsState({
                            modalName: 'selectAdjustInvoiceMethod',

                            status: true,
                          }),
                        )

                        // }

                        // if (!isCreateAdjustedInvoice)

                        //   dispatch(

                        //     setModalsState({

                        //       modalName: 'noRight',

                        //       status: true,

                        //     }),

                        //   )
                      },
                    },

                  row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                    (row?.type === 0 ||
                      row?.type === 1 ||
                      row?.type === 3 ||
                      row?.type === 4) && {
                      label: 'Thay thế hoá đơn',

                      icon: <IconAction className="fa-light fa-file-minus" />,

                      onClick: () => {
                        // if (isCreateReplacementInvoice) {

                        // đã lập thông báo huỷ, điều chỉnh

                        if (![0, 3, 4].includes(row.errAnnouceType)) {
                          let describeText

                          if ([3, 5].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                              row.errAnnouceType,
                            )}. Bạn không được phép thay thế hóa đơn này.`

                            dispatch(showNotification({ describeText }))

                            return
                          }

                          if ([2, 6].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã lập thông báo sai sót với hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                              row.errAnnouceType,
                            )} nhưng chưa gửi CQT. Bạn cần hoàn thành TBSS này.`

                            dispatch(showNotification({ describeText }))

                            return
                          }
                        }

                        if (row.type === INVOICE_TYPE.CANCELLED) {
                          navigate(
                            `/hoa-don-dau-ra/xu-ly-hoa-don/may-tinh-tien/thay-the/${row?.invoiceId}`,
                          )

                          return
                        }

                        if (row.type === INVOICE_TYPE.REPLACED) {
                          if (row.Related?.at(0)) {
                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn Thay thế số <${
                              row.Related.at(0).serial
                            } - ${
                              row.Related.at(0).no ?? 'Chưa câp số'
                            }>. Bạn không thể tiếp tục lập hoá đơn thay thế cho hoá đơn này.`

                            dispatch(showNotification({ describeText }))

                            return
                          }

                          navigate(
                            `/hoa-don-dau-ra/xu-ly-hoa-don/may-tinh-tien/thay-the/${row?.invoiceId}`,
                          )

                          return
                        }

                        // Trường hợp chưa lập tbss

                        if (row.errAnnouceType == 0) {
                          // đã lập hóa đơn thay thế, điều chỉnh

                          if (row.Related?.at(0)) {
                            let relatedType = row.Related.at(0).type

                            if (![1, 2].includes(relatedType)) {
                              ToastHelper.showError(
                                'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                              )

                              return
                            }

                            let relatedTypeVNese =
                              relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'

                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                              row.Related.at(0).serial
                            } - ${
                              row.Related.at(0).no ?? 'Chưa câp số'
                            }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`

                            dispatch(showNotification({ describeText }))

                            return
                          }

                          // kiểm tra xem có lập biên bản hủy không

                          if (row.reportType == 'CANCEL') {
                            let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản hủy. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (hủy).`

                            dispatch(showNotification({ describeText }))

                            return
                          }
                        }

                        dispatch(setSelectedInvoice(row))

                        dispatch(
                          setModalsState({
                            modalName: 'selectReplacementMethod',

                            status: true,
                          }),
                        )

                        // }

                        // if (!isCreateReplacementInvoice)

                        //   dispatch(

                        //     setModalsState({

                        //       modalName: 'noRight',

                        //       status: true,

                        //     }),

                        //   )
                      },
                    },

                  row?.issueStatus == INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                    [0, 1, 3].includes(row?.type) &&
                    (row?.reportStatus == 1 ||
                      [1, 2, 4, 6].includes(row?.invoiceErrAnnouceStatus)) && {
                      label: 'Huỷ hoá đơn',

                      icon: <IconAction className="fa-light fa-file-slash" />,

                      onClick: () => {
                        // if (isCreateCancelInvoice) {

                        if (![0, 1, 4].includes(row.errAnnouceType)) {
                          let describeText = `Hóa đơn <${row.serial} - ${
                            row.no
                          }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                            row.errAnnouceType,
                          )}. Bạn không được phép hủy hóa đơn này.`

                          dispatch(showNotification({ describeText }))

                          return
                        }

                        // Trường hợp chưa lập tbss

                        if (row.errAnnouceType == 0) {
                          // đã lập hóa đơn thay thế, điều chỉnh

                          if (row.Related?.at(0)) {
                            let relatedType = row.Related.at(0).type

                            if (![1, 2].includes(relatedType)) {
                              ToastHelper.showError(
                                'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                              )

                              return
                            }

                            let relatedTypeVNese =
                              relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'

                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                              row.Related.serial
                            } - ${
                              row.Related.no ?? 'Chưa câp số'
                            }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`

                            dispatch(showNotification({ describeText }))

                            return
                          }

                          // kiểm tra xem có lập biên bản điều chỉnh không

                          if (row.reportType) {
                            if (row.reportType == 'ADJUST') {
                              let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản điều chỉnh. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (điều chỉnh).`

                              dispatch(showNotification({ describeText }))

                              return
                            }
                          }
                        }

                        dispatch(setSelectedInvoice(row))

                        dispatch(
                          setModalsState({
                            modalName: 'selectCancelationMethod',

                            status: true,
                          }),
                        )

                        // }

                        // if (!isCreateCancelInvoice)

                        //   dispatch(

                        //     setModalsState({

                        //       modalName: 'noRight',

                        //       status: true,

                        //     }),

                        //   )
                      },
                    },

                  {
                    type: 'divider',
                  },

                  /* : null */ // Thay đổi trạng thái thanh toán

                  row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                    row?.type !== INVOICE_TYPE.DELETED && {
                      label: 'Đã thanh toán',

                      disabled: isUpdatingPaidStatus,

                      icon: isUpdatingPaidStatus ? (
                        <Spinner animation="grow" size="sm" />
                      ) : (
                        <IconAction
                          className={Utils.cn(
                            row.isPayment
                              ? 'fa-light fa-check text-success'
                              : 'fa-light fa-square',
                          )}
                        />
                      ),

                      onClick: () => {
                        updateInvoicePaidStatus({
                          invoiceId: row.invoiceId,

                          isPaid: !row.isPayment,
                        })
                      },
                    },

                  // Chuyển sang hóa đơn giấy

                  [
                    INVOICE_STATUS.AUTHORITY_ACCEPT,

                    INVOICE_STATUS.AUTHORITY_DENY,

                    INVOICE_STATUS.AUTHORITY_CODE_GIVEN,

                    INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
                  ].includes(row?.issueStatus)
                    ? {
                        label: 'Chuyển thành hóa đơn giấy',

                        icon: (
                          <IconAction className="fa-light fa-paper-plane-alt" />
                        ),

                        onClick: () => {
                          dispatch(setSelectedInvoice(row))

                          dispatch(
                            setModalsState({
                              modalName: 'convertPaperModal',

                              status: true,
                            }),
                          )
                        },
                      }
                    : null,
                ]}
              />
            </div>
          )
        },
      },
    ]

    return arr
  }, [
    companyUnitList,

    dispatch,

    fireConfirm,

    handleDelete,

    handleDownloadPdf,

    handleDownloadXml,

    handleViewInvoice,

    isUpdatingPaidStatus,

    navigate,

    t,

    updateInvoicePaidStatus,
  ])

  const dynamicColumns = useAppDnD(columns)

  useEffect(() => {
    setFilterParams({ q: debouncedSearch })
  }, [debouncedSearch, setFilterParams])

  useEffect(() => {
    dispatch(resetModals())
  }, [dispatch])

  return (
    <ContentContainer>
      <ContentContainer.Header
        titleContent="HĐ khởi tạo từ máy tính tiền"
        description={
          <div className="d-flex justify-content-center align-items-center">
            <i className="fa-solid fa-message-question text-primary fa-2x mr-1" />

            <span className="fw-medium fs-6 fst-italic">
              Hóa đơn khởi tạo từ máy tính tiền cần gửi đến CQT trong ngày
            </span>
          </div>
        }
        toolBar={
          <Space size={3}>
            <CustomAntButton
              text={t('AddNew')}
              iconCn="fas fa-plus"
              antProps={{
                type: 'primary',

                onClick: () =>
                  navigate({
                    pathname: '/hoa-don-dau-ra/hoa-don/may-tinh-tien/them-moi',
                  }),
              }}
            />

            <CustomAntButton
              text="Nhập khẩu"
              iconCn="far fa-file-import"
              antProps={{
                type: 'default',

                onClick: () =>
                  dispatch(
                    setModalsState({
                      modalName: 'upload',

                      status: true,
                    }),
                  ),
              }}
            />

            <CustomAntButton
              text={'Gửi dữ liệu MTT đến CQT'}
              iconCn="far fa-file-export"
              antProps={{
                type: 'default',

                onClick: () => {
                  dispatch(
                    setModalsState({
                      modalName: 'sentFromPos',

                      status: true,
                    }),
                  )
                },
              }}
            />
          </Space>
        }
      >
        <Flex className="w-100" justify={'space-between'}>
          {selectedInvoices.length > 0 ? (
            <>
              <Flex className="w-100" gap={10}>
                <CustomAntButton
                  iconCn="fa-light fa-paper-plane"
                  text={'Phát hành (' + selectedInvoices.length + ')'}
                  antProps={{
                    type: 'primary',

                    disabled: selectedInvoices.some(
                      (invoice) =>
                        invoice.issueStatus !==
                        INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
                    ),

                    // className: 'btn btn-primary',

                    onClick: selectedInvoices.some(
                      (invoice) =>
                        invoice.issueStatus !==
                        INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
                    )
                      ? undefined
                      : () => {
                          dispatch(
                            setModalsState({
                              modalName: 'issueInvoicesFromPos',

                              status: true,
                            }),
                          )
                        },
                  }}
                />

                <CustomAntButton
                  text="Đã thanh toán"
                  iconCn="fa-regular fa-check"
                  variant="success"
                  isLoading={isUpdatingBulkPaidStatus}
                  antProps={{
                    type: 'primary',

                    onClick: () => {
                      updateBulkInvoicePaidStatus({
                        invoiceIds: selectedInvoices

                          .filter((invoice) => !invoice.isPayment)

                          .map((invoice) => invoice.invoiceId),

                        isPayment: true,
                      })
                    },
                  }}
                />

                <CustomAntButton
                  iconCn="fa-regular fa-trash"
                  text={Utils.cn('Xoá ', selectedInvoices.length, 'hóa đơn')}
                  antProps={{
                    type: 'primary',

                    danger: true,

                    onClick: async () => {
                      const result = await fireConfirm(
                        Utils.cn(
                          'Xác nhận xóa',

                          selectedInvoices.length,

                          'hóa đơn',
                        ),

                        'Bạn chắc chắn muốn xóa các hóa đơn này?',
                      )

                      if (!result.value) return false

                      handleDelete(
                        selectedInvoices.map((item) => item.invoiceId),
                      )
                    },
                  }}
                />

                <CustomAntButton
                  iconCn="fa-regular fa-paper-plane"
                  text={Utils.cn(
                    'Gửi lại ',

                    selectedInvoices.length,

                    'hóa đơn lên CQT',
                  )}
                  antProps={{
                    type: 'primary',

                    disabled: !selectedInvoices?.some(
                      ({ sendTaxStatus }) => sendTaxStatus == 'SEND_ERROR',
                    ),

                    onClick: async () => {
                      const result = await fireConfirm(
                        Utils.cn('Xác nhận gửi lại', 'hóa đơn lên CQT'),

                        'Chương trình chỉ thực hiện gửi lại các hóa đơn đã cấp số, có trạng thái gửi CQT là Gửi lỗi',
                      )

                      if (result.isDismissed) return false

                      handleResendTaxInvoices(selectedInvoices)
                    },
                  }}
                />

                <CustomAntButton
                  iconCn="fa-regular fa-file-download"
                  text={Utils.cn('Tải về ', selectedInvoices.length)}
                  antProps={{
                    type: 'primary',

                    disabled: !selectedInvoices?.some((invoice) => {
                      return (
                        (invoice.issueStatus ===
                          INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
                          invoice.issueStatus ===
                            INVOICE_STATUS.AUTHORITY_ACCEPT) &&
                        invoice.type !== INVOICE_TYPE.CANCELLED &&
                        invoice.type !== INVOICE_TYPE.REPLACED &&
                        invoice.type !== INVOICE_TYPE.DELETED
                      )
                    }),

                    onClick: () => {
                      // if (allCanDownloadInvoice) {

                      downloadInvoices({
                        invoices: selectedInvoices,
                      })
                    },
                  }}
                />
              </Flex>
            </>
          ) : (
            <Space
              wrap
              className={Utils.cn(selectedInvoices.length > 0 ? 'd-none' : '')}
            >
              <CustomAntInput
                type="search"
                borderRadius="sm"
                width={250}
                inputProps={{
                  value: search,

                  onChange: (e) => setSearch(e.target.value),
                }}
              />

              <FilterPopover
                defaultValues={filterDefault}
                savedKey="gFilterListInvoiceFromPOS"
                onApply={setFilterParams}
              />
            </Space>
          )}

          <div>
            <AppDnD
              defaultColumns={columns}
              localStorageName="invoiceFromPos"
            />
          </div>
        </Flex>
      </ContentContainer.Header>

      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={listInvoiceData?.tableData}
          isLoading={isGettingListData || isRefetchingListData}
          isSelectable
          isClickable
          persistTableHead
          handleDoubleClickRow={(row) => handleViewInvoice(row.invoiceId)}
          selectedRows={{
            clearSelectedRows: toggledClearRows,

            selectableRowSelected: (row) => {
              _.includes(selectedInvoices, row)
            },

            handleSelectedRows: ({ selectedRows }) => {
              dispatch(setSelectedInvoices(selectedRows))
            },
          }}
          pagination={{
            currentPage: filterParams.page,

            rowsPerPage: filterParams.limit,

            total: listInvoiceData?.total,

            onChangePage: (page) => setFilterParams({ page: parseInt(page) }),

            onChangeRowsPerPage: (limit) =>
              setFilterParams({ page: 1, limit: parseInt(limit) }),
          }}
        />
      </ContentContainer.Body>

      <ModalSendFromPOS
        invoiceType="INVOICE"
        show={modalStates.sentFromPos}
        onOpenChange={(status) => {
          dispatch(
            setModalsState({
              modalName: 'sentFromPos',

              status,
            }),
          )
        }}
        refetchListInvoice={refetchListInvoice}
      />

      <ModalListInvoiceFromPOS
        invoiceType="INVOICE"
        show={modalStates.listInvoiceFromPOS}
        onOpenChange={(status) => {
          dispatch(
            setModalsState({
              modalName: 'listInvoiceFromPOS',

              status,
            }),
          )

          dispatch(
            setModalsState({
              modalName: 'sentFromPos',

              status: true,
            }),
          )
        }}
      />

      <IssueInvoicesFromPosModal
        show={modalStates.issueInvoicesFromPos}
        onOpenChange={(status) =>
          dispatch(
            setModalsState({
              modalName: 'issueInvoicesFromPos',

              status,
            }),
          )
        }
        refetchListInvoice={refetchListInvoice}
      />

      <IssueInvoiceFromPos
        invoice={selectedInvoice}
        // issueInvoiceFromPosHandle={issueInvoiceFromPosHandle}

        onIssueSuccess={refetchListInvoice}
        show={modalStates.issueInvoiceFromPos}
        onHide={() => {
          dispatch(
            setModalsState({
              modalName: 'issueInvoiceFromPos',

              status: false,
            }),
          )

          dispatch(setSelectedInvoice(null))
        }}
      />

      <ModalSendInvoiceMail
        onHandleApply={() => {
          dispatch(setSelectedInvoice(null))

          dispatch(setSelectedInvoices([]))

          refetchListInvoice()
        }}
      />

      <ExplanationInvoiceModal
        show={modalStates.explanation}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'explanation',

              status: false,
            }),
          )
        }
        title="Gửi thông báo giải trình cho khách hàng"
        invoice={selectedInvoice}
        buttonAgreeOnClick={(values) => {
          sendExplanationAnnounceToCustomer(values)

          dispatch(
            setModalsState({
              modalName: 'sendExplanationAnnounce',

              status: false,
            }),
          )
        }}
      />

      <ModalConvertPaper
        showModalConvertPaper={modalStates.convertPaperModal}
        setShowModalConvertPaper={(status) => {
          dispatch(
            setModalsState({
              modalName: 'convertPaperModal',

              status,
            }),
          )
        }}
        handleConvertPaper={(converterName) =>
          handleConvertToPaper({
            lookupCode: selectedInvoice.lookupCode,

            converterName,
          })
        }
      />

      <ModalSelectCancellationMethod
        show={modalStates.selectCancelationMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',

              status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập thông báo hóa đơn điện tử có sai sót gửi cơ quan thuế và lập biên bản điện tử để thỏa thuận với người mua trước khi thực hiện hủy hóa đơn."
        createCancellationReportSelect={
          selectedInvoice?.issueStatus ===
            INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
          [0, 1, 3].includes(selectedInvoice?.type) &&
          selectedInvoice?.reportStatus == 1
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/hoa-don/bien-ban-huy/them-moi/${selectedInvoice?.invoiceId}`,
                )
            : null
        }
        createErrorAnnounceSelect={
          [1, 4, 6].includes(selectedInvoice?.invoiceErrAnnouceStatus)
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot/${selectedInvoice?.invoiceId}?invoiceClassify=MTT`,
                )
            : null
        }
        cancelInvoiceSelect={
          [0, 1].includes(selectedInvoice?.type)
            ? () => {
                dispatch(
                  setModalsState({
                    modalName: 'selectCancelationMethod',

                    status: false,
                  }),
                )

                dispatch(
                  setModalsState({
                    modalName: 'cancel',

                    status: true,
                  }),
                )
              }
            : null
        }
      />

      <ModalSelectAdjustInvoiceMethod
        show={modalStates.selectAdjustInvoiceMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectAdjustInvoiceMethod',

              status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập biên bản điện tử để thỏa thuận với người mua trước khi lập hóa đơn điều chỉnh."
        createAdjustedReportSelect={
          selectedInvoice?.issueStatus ===
            INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
          [0, 5].includes(selectedInvoice?.type) &&
          selectedInvoice?.reportStatus == 1
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/hoa-don/bien-ban-dieu-chinh/them-moi/${selectedInvoice?.invoiceId}`,
                )
            : null
        }
        createAdjustAnnounceSelect={
          [1, 4, 6].includes(selectedInvoice?.invoiceErrAnnouceStatus)
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot/${selectedInvoice?.invoiceId}?invoiceClassify=MTT`,
                )
            : null
        }
        adjustInvoiceSelect={
          [0, 5].includes(selectedInvoice?.type)
            ? () => {
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/may-tinh-tien/dieu-chinh/${selectedInvoice?.invoiceId}`,
                )
              }
            : null
        }
      />

      <ModalConfirm
        header="Xác nhận"
        content="Bạn cần hủy hóa đơn bị sai sót trước khi lập hóa đơn thay thế. Bạn có muốn hủy hóa đơn này không?"
        show={modalStates.selectReplacementMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({ modalName: 'selectReplacementMethod', status }),
          )
        }
        handleConfirm={(e) => {
          e.preventDefault()

          dispatch(
            setModalsState({
              modalName: 'selectReplacementMethod',

              status: false,
            }),
          )

          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',

              status: true,
            }),
          )
        }}
      />

      <CancelInvoiceModal
        show={modalStates.cancel}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'cancel',

              status: false,
            }),
          )
        }
        title="Huỷ hoá đơn"
        invoice={selectedInvoice}
        buttonAgreeOnClick={(value) =>
          cancelInvoice({ ...value, invoiceId: selectedInvoice.invoiceId })
        }
      />

      <ModalConfirm
        header="Hoá đơn điều chỉnh"
        content="Hoá đơn này đã được lập hoá đơn điều chỉnh. Bạn có muốn tiếp tục lập hoá

      đơn điều chỉnh cho hoá đơn này không"
        show={isInvoiceAdjust}
        setShow={setIsInvoiceAdjust}
        handleConfirm={(e) => {
          e.preventDefault()

          setIsInvoiceAdjust(false)

          navigate(
            `/hoa-don-dau-ra/xu-ly-hoa-don/may-tinh-tien/dieu-chinh/${selectedInvoice?.invoiceId}`,
          )
        }}
      />

      <ModalConfirm
        show={hsmSignModalInfo.show}
        setShow={(value) =>
          setHSMSignModalInfo((old) => ({
            show: value,

            invoiceIds: !value ? false : old?.invoiceIds,
          }))
        }
        header="Ký số HSM"
        content="Chương trình sẽ thực hiện ký số HSM. Bạn chắc chắn vẫn ký?"
        handleConfirm={handleHsmSignSummaryInvoice}
      />

      <ModalUploadInvoiceExcel
        show={modalStates.upload}
        setShow={(status) =>
          dispatch(setModalsState({ modalName: 'upload', status }))
        }
        refetchListInvoice={refetchListInvoice}
      />

      <CloneMultiInvoiceModal
        show={modalStates.cloneMultiInvoiceModal}
        type="mtt"
        invoiceId={cloneMultiInvoiceModal.invoiceId}
        onOpenChange={(value) =>
          dispatch(
            setModalsState({
              modalName: 'cloneMultiInvoiceModal',
              status: value,
            }),
            setCloneMultiInvoiceModal({
              ...cloneMultiInvoiceModal,
              show: value,
            }),
          )
        }
        reloadList={refetchListInvoice}
      ></CloneMultiInvoiceModal>
    </ContentContainer>
  )
}
