import { resetDefaultParams, setFilterParams } from 'app/filterSlice'
import Loading from 'general/components/Loading'
import {
  INVOICE_CLASSIFY,
  INVOICE_STATUS,
  INVOICE_TYPE,
} from 'general/constants/AppConstants'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

/**
 *
 * @param {object} props
 * @param {string} props.additionalClassName
 * @param {string} props.title
 * @param {string} props.subTitle
 * @param {React.ReactNode} props.headerSidebar
 * @param {object} props.data
 * @param {boolean} props.isLoading
 * @param {object} props.datePickerData
 * @returns
 */
function CardInvoiceStatus(props) {
  // MARK: --- Params ---
  const {
    additionalClassName,
    title,
    subTitle,
    headerSidebar,
    data,
    isLoading,
    datePickerData,
  } = props
  const dispatch = useDispatch()

  const { invoice, ticket } = useAppSelector((s) => s.auth.activeInvoice)
  const invoicePath = useMemo(() => {
    switch (datePickerData?.invoiceClassify) {
      default:
      case 'ALL':
      case 'HD':
        return '/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don'
      case 'MTT':
        return '/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don'
      case 'PXK':
        return '/'
    }
  }, [datePickerData?.invoiceClassify])
  const toInvoiceList = useCallback(
    (param) => {
      let storeObj = {}
      if (invoice) {
        storeObj =
          datePickerData.invoiceClassify === INVOICE_CLASSIFY.HD
            ? {
                storeName: 'invoiceList',
                localSavedName: 'gFiltersInvoiceList',
              }
            : datePickerData.invoiceClassify === INVOICE_CLASSIFY.MTT
              ? {
                  storeName: 'listInvoiceFromPOS',
                  localSavedName: 'gFilterListInvoiceFromPOS',
                }
              : datePickerData.invoiceClassify === INVOICE_CLASSIFY.PXK
                ? {}
                : {
                    storeName: 'invoiceList',
                    localSavedName: 'gFiltersInvoiceList',
                  }
      }
      if (ticket) {
        storeObj = {
          storeName: 'issuedTicketList',
          localSavedName: 'gFilterListIssuedTicket',
        }
      }
      dispatch(resetDefaultParams(storeObj))
      dispatch(
        setFilterParams({
          storeObj,
          data: {
            ...param,
            period: datePickerData?.period,
            startDate: datePickerData.startDate,
            endDate: datePickerData.endDate,
          },
        }),
      )
    },
    [
      datePickerData.endDate,
      datePickerData.invoiceClassify,
      datePickerData?.period,
      datePickerData.startDate,
      dispatch,
      invoice,
      ticket,
    ],
  )

  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)

  return (
    <div className="CardInvoiceStatus h-100">
      <div className={`card card-custom card-stretch ${additionalClassName}`}>
        {isLoading ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Loading showBackground={false} />
          </div>
        ) : (
          <>
            {/* card header */}
            <div className="card-header border-0 d-flex flex-wrap align-items-center justify-content-between">
              <div
                className={`${
                  headerSidebar
                    ? 'd-flex flex-wrap justify-content-between w-100'
                    : ''
                }`}
              >
                {/* card title */}
                <h3 className="card-title">
                  <span className="card-label font-weight-bolder font-size-h4 text-dark-75">
                    {title}
                  </span>
                  <span className="d-block text-muted mt-2 font-size-base">
                    {subTitle}
                  </span>
                </h3>
                <div>{headerSidebar}</div>
              </div>
            </div>

            {/* card body */}
            <div className="card-body">
              <div className="w-100 h-100 d-flex">
                <table className="table table-bordered m-0">
                  <tbody>
                    <tr>
                      <td className="w-50">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <p>
                            {systemType?.ticket
                              ? 'Vé chưa phát hành'
                              : 'Hóa đơn chưa phát hành, gửi/ ký lỗi'}
                          </p>
                          <p className="font-weight-bolder font-size-h2">
                            {Utils.formatNumber(
                              data?.notIssuedInvoicesCount ?? 0,
                            )}
                          </p>
                          <Link
                            to={
                              invoice
                                ? invoicePath
                                : '/hoa-don-dau-ra/tem-ve/ve-dien-tu/xuat-ve'
                            }
                            className="cursor-pointer text-primary"
                            onClick={() =>
                              toInvoiceList({
                                issueStatus:
                                  INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
                              })
                            }
                          >
                            Xem chi tiết
                          </Link>
                        </div>
                      </td>
                      <td className="w-50">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <p>{systemType?.ticket ? 'Vé huỷ' : 'Hóa đơn huỷ'}</p>
                          <p className="font-weight-bolder font-size-h2">
                            {Utils.formatNumber(
                              data?.canceledInvoicesCount ?? 0,
                            )}
                          </p>
                          <Link
                            to={
                              invoice
                                ? invoicePath
                                : '/hoa-don-dau-ra/tem-ve/ve-dien-tu/ve-da-xuat'
                            }
                            className="cursor-pointer text-primary"
                            onClick={() =>
                              toInvoiceList({
                                types: INVOICE_TYPE.CANCELLED,
                              })
                            }
                          >
                            Xem chi tiết
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-50">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <p>
                            {systemType?.ticket
                              ? 'Vé thay thế'
                              : 'Hóa đơn thay thế'}
                          </p>
                          <p className="font-weight-bolder font-size-h2">
                            {Utils.formatNumber(
                              data?.replacedInvoicesCount ?? 0,
                            )}
                          </p>
                          <Link
                            to={
                              invoice
                                ? invoicePath
                                : '/hoa-don-dau-ra/tem-ve/ve-dien-tu/ve-da-xuat'
                            }
                            className="cursor-pointer text-primary"
                            onClick={() =>
                              toInvoiceList({
                                types: INVOICE_TYPE.REPLACE,
                              })
                            }
                          >
                            Xem chi tiết
                          </Link>
                        </div>
                      </td>
                      <td className="w-50">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <p>
                            {systemType?.ticket
                              ? 'Vé điều chỉnh'
                              : 'Hóa đơn điều chỉnh'}
                          </p>
                          <p className="font-weight-bolder font-size-h2">
                            {Utils.formatNumber(
                              data?.adjustedInvoicesCount ?? 0,
                            )}
                          </p>
                          <Link
                            to={
                              invoice
                                ? invoicePath
                                : '/hoa-don-dau-ra/tem-ve/ve-dien-tu/ve-da-xuat'
                            }
                            className="cursor-pointer text-primary"
                            onClick={() =>
                              toInvoiceList({
                                types: INVOICE_TYPE.ADJUST,
                              })
                            }
                          >
                            Xem chi tiết
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CardInvoiceStatus
