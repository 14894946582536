import { setFilterParams } from 'app/filterSlice'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from './useRedux'

/**
 * @param {import('app/filterSlice').FilterState} storeName - Tên redux store trong FilterReducer.
 * @param {import('general/utils/Global').GlobalFilterType} localSavedName - Tên value trong localstorage.
 * @returns {[import('app/filterSlice').FilterStateValue, (filter: import('app/filterSlice').FilterStateValue) => void]} - An array containing the current filter state and a function to update it.
 */
export default function useFilter(storeName, localSavedName) {
  console.log({ storeName, localSavedName })
  const filter = useAppSelector((s) => s.filter[storeName])
  const dispatch = useAppDispatch()
  const setFilter = useCallback(
    (values) => {
      dispatch(
        setFilterParams({
          storeObj: {
            localSavedName,
            storeName,
          },
          data: values,
        }),
      )
    },
    [dispatch, localSavedName, storeName],
  )
  return [filter, setFilter]
}
