import { memo, useCallback, useState } from 'react'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import {
  INVOICE_STATUS,
  INVOICE_TYPE,
  MESSAGE_SUCCESS,
} from 'general/constants/AppConstants'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import invoiceApi from 'api/invoiceApi'
import {
  useMutationCheckMessage,
  useMutationDownloadInvoice,
  useMutationDownloadPdf,
  useMutationDownloadXml,
  useMutationHsmSigning,
  useMutationInvoicePaidStatus,
  useMutationViewPdf,
  useQueryListInvoice,
} from '../../ListInvoice/queries'
import useCheckPermission from 'hooks/useCheckPermission'
import useSearchDebounce from 'hooks/useSearchDebounce'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ModalConvertPaper from 'features/Search/components/ModalConvertPaper'
import { setModalsState } from 'features/Invoice/invoiceSlice'
import { setAppSpinning, showNotification } from 'app/appSlice'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { CloneMultiInvoiceModal } from 'general/components/Modal/CloneMultiInvoiceModal'
import { shallowEqual } from 'react-redux'

const TableActions = memo((props) => {
  const { row, navigate, activeInvoice, setActiveInvoice, filterParams } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { debouncedSearch } = useSearchDebounce('')
  const { company } = useAppSelector((state) => state?.system)
  const { modalStates } = useAppSelector((state) => state.invoice, shallowEqual)

  //Mutation
  const { mutate: viewPdfInvoice } = useMutationViewPdf()
  const { mutate: downloadInvoices } = useMutationDownloadInvoice()
  const { mutate: fetchTaxAuthorityMessage } = useMutationCheckMessage()
  const { mutate: downloadPdf } = useMutationDownloadPdf()
  const { mutate: updateInvoicePaidStatus } = useMutationInvoicePaidStatus()
  const { downloadXml } = useMutationDownloadXml()
  const { mutate: hsmSignInvoiceMutation } = useMutationHsmSigning('PXK')

  // State
  const [isInvoiceAdjust, setIsInvoiceAdjust] = useState(false)
  const [convertPaperModalConfig, setConvertPaperModalConfig] = useState({
    show: false,
    lookupCode: null,
  })
  const [reIssueModalConfig, setReIssueModalConfig] = useState({
    show: false,
    invoiceId: null,
  })
  const [reSignHSMModalConfig, setReSignHSMModalConfig] = useState({
    show: false,
    invoiceId: null,
  })

  const [cloneMultiInvoiceModal, setCloneMultiInvoiceModal] = useState({
    show: false,
    invoiceId: null,
  })

  const { isReleaseOriginalInvoice } = useCheckPermission([
    {
      keyAction: 'isReleaseOriginalInvoice',
      permissionCode: 'INVOICE_RELEASE_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'isReleaseOriginalInvoice',
      permissionCode: 'INVOICE_RELEASE_ORIGINAL_INVOICE',
    },
  ])

  const {
    canCreateInvoice,
    canEditInvoice,
    isDeleteInvoice,
    isSendInvoiceToCustomer,
    isCreateReplacementInvoice,
    isDownloadInvoice,
    isCreateAdjustedInvoice,
    isCreateCancelInvoice,
  } = useCheckPermission([
    {
      keyAction: 'canCreateInvoice',
      permissionCode: 'INVOICE_ADD_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'canEditInvoice',
      permissionCode: 'INVOICE_EDIT_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'isDeleteInvoice',
      permissionCode: 'INVOICE_DELETE_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'isSendInvoiceToCustomer',
      permissionCode: 'INVOICE_SEND_INVOICE_TO_CUSTOMER_ORIGINAL',
    },
    {
      keyAction: 'isCreateReplacementInvoice',
      permissionCode: 'INVOICE_CREATE_REPLACEMENT_INVOICE',
    },
    {
      keyAction: 'isDownloadInvoice',
      permissionCode: 'INVOICE_DOWNLOAD_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'isCreateAdjustedInvoice',
      permissionCode: 'INVOICE_CREATE_ADJUSTED_INVOICE',
    },
    {
      keyAction: 'isCreateCancelInvoice',
      permissionCode: 'INVOICE_CREATE_CANCEL_INVOICE',
    },
  ])

  const { refetchListInvoice } = useQueryListInvoice(
    debouncedSearch,
    filterParams,
  )

  const { activeSigningMethod } = useAppSelector(
    (state) => state.system.company,
  )

  const issueInvoiceHandler = useCallback(
    async (row, invoiceIssueStatus) => {
      if (isReleaseOriginalInvoice) {
        switch (invoiceIssueStatus) {
          case INVOICE_STATUS.NOT_SENT_TO_AUTHORITY:
            if (activeSigningMethod == 'HSM') {
              // hsmSignInvoiceMutation(row?.invoiceId)
              setHSMSignModalConfig({
                show: true,
                invoiceId: row?.invoiceId,
              })
            } else {
              let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
              const urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},${row?.invoiceId},PXK`
              Utils.openToolSignInvoice(
                urlSchema,
                accessToken,
                dispatch,
                setAppSpinning,
              )
            }
            break
          case INVOICE_STATUS.SEND_ERROR:
            setReIssueModalConfig({
              show: true,
              invoiceId: row?.invoiceId,
            })

            break
          case INVOICE_STATUS.SIGN_ERROR:
            if (activeSigningMethod == 'HSM') {
              setReSignHSMModalConfig({
                show: true,
                invoiceId: row?.invoiceId,
              })
            } else {
              console.log('Chưa xử lý trạng thái ký lỗi usb_token')
            }
            break
        }
      }
      if (!isReleaseOriginalInvoice)
        dispatch(
          setModalsState({
            modalName: 'noRight',
            status: true,
          }),
        )
    },
    [activeSigningMethod, company?.taxCode, dispatch, isReleaseOriginalInvoice],
  )

  // hsm
  const [hsmSignModalConfig, setHSMSignModalConfig] = useState({
    show: false,
    invoiceId: null,
  })

  //
  const handleConvertPaper = async (converterName) => {
    dispatch(setAppSpinning(true))
    var res
    try {
      res = await invoiceApi.convertPaper({
        lookupCode: convertPaperModalConfig.lookupCode,
        converterName,
      })
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }
    let newFile = new File([res], 'Hóa đơn chuyển đổi.pdf', {
      type: 'application/pdf',
    })
    var fileURL = URL.createObjectURL(newFile)
    window.open(fileURL, '_blank')
  }

  const reIssueInvoice = async () => {
    dispatch(setAppSpinning(true))

    try {
      let res = await invoiceApi.reIssue({
        invoiceIds: [reIssueModalConfig.invoiceId],
      })
      dispatch(setAppSpinning(false))

      if (res?.data) res = res.data
      if (
        !!res?.reIssueSuccessInvoices?.find(
          ({ invoiceId }) => invoiceId == reIssueModalConfig.invoiceId,
        )
      ) {
        ToastHelper.showSuccess(MESSAGE_SUCCESS.RE_ISSUED_INVOICE)
        // reload page
        refetchListInvoice()
        return
      }
      // Phát hành lại thất bại
      ToastHelper.showError(res?.reIssueErrorInvoices?.at(0)?.reason)
      refetchListInvoice()
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  const reSignHSM = async () => {
    dispatch(setAppSpinning(true))

    try {
      let res = await invoiceApi.reSignHSM({
        invoiceIds: [reSignHSMModalConfig.invoiceId],
      })
      dispatch(setAppSpinning(false))

      if (res?.data) res = res.data
      if (
        !!res?.successSignInvoices?.find(
          ({ invoiceId }) => invoiceId == reSignHSMModalConfig.invoiceId,
        )
      ) {
        ToastHelper.showSuccess(MESSAGE_SUCCESS.RE_ISSUED_INVOICE)
        // reload page
        refetchListInvoice()
        return
      }
      // Phát hành lại thất bại
      ToastHelper.showError(res?.errorSignInvoices?.at(0)?.reason)
      refetchListInvoice()
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  return (
    <>
      <TableAction
        titleActionText="Xem"
        icon={<IconAction className="fa-light fa-eye" />}
        onClick={() => viewPdfInvoice(row)}
      />
      {[
        INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
        INVOICE_STATUS.SEND_ERROR,
        INVOICE_STATUS.SIGN_ERROR,
      ].includes(_.get(row, 'issueStatus')) &&
      _.get(row, 'type') !== INVOICE_TYPE.DELETED ? (
        <TableAction
          titleActionText={'Phát hành hoá đơn'}
          icon={<IconAction className="fa-light fa-paper-plane" />}
          onClick={() => issueInvoiceHandler(row, _.get(row, 'issueStatus'))}
        />
      ) : null}

      {_.get(row, 'issueStatus') === INVOICE_STATUS.NOT_SENT_TO_AUTHORITY &&
      _.get(row, 'type') !== INVOICE_TYPE.DELETED ? (
        <TableAction
          titleActionText={t('Edit')}
          icon={<IconAction className="fa-light fa-pen-to-square" />}
          onClick={() => {
            !canEditInvoice
              ? dispatch(
                  setModalsState({
                    modalName: 'noRight',
                    status: true,
                  }),
                )
              : navigate(
                  `/hoa-don-dau-ra/hoa-don/phieu-xuat-kho/cap-nhat/${row?.invoiceId}`,
                )
          }}
        />
      ) : null}
      {[
        INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
        INVOICE_STATUS.SIGN_ERROR,
        INVOICE_STATUS.SEND_ERROR,
      ].includes(_.get(row, 'issueStatus')) &&
      _.get(row, 'type') !== INVOICE_TYPE.DELETED ? (
        <TableAction
          titleActionText="Xoá"
          icon={<IconAction className="fa-light fa-trash" />}
          onClick={() => {
            const handleDeleteAction = () => {
              setActiveInvoice(row)
              dispatch(
                setModalsState({
                  modalName: 'delete',
                  status: true,
                }),
              )
            }
            return !isDeleteInvoice
              ? dispatch(
                  setModalsState({
                    modalName: 'noRight',
                    status: true,
                  }),
                )
              : handleDeleteAction()
          }}
        />
      ) : null}
      <TableAction
        titleActionText="Gửi hoá đơn cho khách hàng"
        icon={<IconAction className="fa-light fa-envelope" />}
        onClick={() => {
          const handleSentMailAction = () => {
            setActiveInvoice(row)
            dispatch(
              setModalsState({
                modalName: 'sendDraftInvoice',
                status: true,
              }),
            )
          }
          !isSendInvoiceToCustomer
            ? dispatch(
                setModalsState({
                  modalName: 'noRight',
                  status: true,
                }),
              )
            : handleSentMailAction()
        }}
      />
      {(_.get(row, 'issueStatus') === INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
        _.get(row, 'issueStatus') === INVOICE_STATUS.AUTHORITY_ACCEPT) &&
      _.get(row, 'type') === INVOICE_TYPE.CANCELLED &&
      _.get(row, 'type') === INVOICE_TYPE.REPLACED ? (
        <TableAction
          icon={<IconAction className="fa-light fa-file-download" />}
          titleActionText="Tải xuống"
          onClick={() => {
            !isDownloadInvoice
              ? dispatch(
                  setModalsState({
                    modalName: 'noRight',
                    status: true,
                  }),
                )
              : downloadInvoices({
                  invoices: [row],
                })
          }}
        />
      ) : null}
      {/* Dropdown */}
      <TableAction
        type="dropdown"
        items={[
          row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
            row?.type !== INVOICE_TYPE.DELETED && {
              label: 'Tải hóa đơn PDF',
              icon: <IconAction className="fa-light fa-file-download" />,
              onClick: () => downloadPdf(row?.invoiceId),
            },
          row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
            row?.type !== INVOICE_TYPE.DELETED && {
              label: 'Tải hóa đơn XML',
              icon: <IconAction className="fa-light fa-file-code" />,
              onClick: () => downloadXml(row?.xmlString),
            },
          {
            label: 'Nhân bản hóa đơn',
            icon: <IconAction className="fa-light fa-copy" />,
            onClick: () => {
              if (canCreateInvoice)
                navigate(
                  `/hoa-don-dau-ra/hoa-don/phieu-xuat-kho/them-moi/${row?.invoiceId}`,
                )
              if (!canCreateInvoice)
                dispatch(
                  setModalsState({
                    modalName: 'noRight',
                    status: true,
                  }),
                )
            },
          },
          {
            label: 'Nhân bản hóa đơn hàng loạt',
            icon: <IconAction className="fa-light fa-copy" />,
            onClick: () => {
              dispatch(
                setModalsState({
                  modalName: 'cloneMultiInvoiceModal',
                  status: true,
                }),
              )
              setCloneMultiInvoiceModal({
                show: true,
                invoiceId: row?.invoiceId,
              })
            },
          },
          (row?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
            row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
            row?.type === 0 && {
              label: 'Gửi thông báo giải trình cho KH',
              icon: <IconAction className="fa-light fa-envelope" />,
              onClick: () => {
                if (![0, 4].includes(row.errAnnouceType)) {
                  let describeText = `Hóa đơn <${row.serial} - ${
                    row.no
                  }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là <${InvoiceHelper.errAnnouceTypeToVnese(
                    row.errAnnouceType,
                  )}>. Bạn không được phép gửi thông báo giải trình sai sót cho hóa đơn này.`
                  dispatch(showNotification({ describeText }))
                  return
                }

                // Trường hợp chưa lập tbss
                if (row.errAnnouceType == 0) {
                  // đã lập hóa đơn thay thế, điều chỉnh
                  if (row.Related?.at(0)) {
                    let relatedType = row.Related.at(0).type
                    if (![1, 2].includes(relatedType)) {
                      ToastHelper.showError(
                        'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                      )
                      return
                    }

                    let relatedTypeVNese =
                      relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'
                    let describeText = `Hóa đơn <${row.serial} - ${
                      row.no
                    }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                      row.Related.serial
                    } - ${
                      row.Related.no ?? 'Chưa câp số'
                    }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`
                    dispatch(showNotification({ describeText }))
                    return
                  }

                  // kiểm tra xem có lập biên bản hủy hay điều chỉnh không
                  if (row.reportType) {
                    let reportTypeVnese =
                      row.reportType == 'CANCEL' ? 'hủy' : 'điều chỉnh'
                    let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản ${reportTypeVnese}. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${reportTypeVnese}).`
                    dispatch(showNotification({ describeText }))
                    return
                  }
                }

                setActiveInvoice(row)
                dispatch(
                  setModalsState({
                    modalName: 'explanation',
                    status: true,
                  }),
                )
              },
            },
          row?.issueStatus === INVOICE_STATUS.WAITING_FOR_AUTHORITY && {
            label: 'Kiểm tra trạng thái',
            icon: <IconAction className="fa-light fa-sync" />,
            onClick: () => fetchTaxAuthorityMessage(row?.messageId),
          },
          (row?.type === 0 || row?.type === 5) &&
            (row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
              row?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT) && {
              label: 'Điều chỉnh hoá đơn',
              icon: <IconAction className="fa-light fa-pen-to-square" />,
              onClick: () => {
                if (isCreateAdjustedInvoice) {
                  if (![0, 2, 4].includes(row.errAnnouceType)) {
                    let describeText
                    const errAnnouceTypeText =
                      InvoiceHelper.errAnnouceTypeToVnese(row.errAnnouceType)
                    if ([3, 5].includes(row?.invoiceErrAnnouceStatus)) {
                      describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${errAnnouceTypeText}. Bạn không được phép điều chỉnh hóa đơn này.`
                      dispatch(showNotification({ describeText }))
                      return
                    }
                    if ([2, 6].includes(row?.invoiceErrAnnouceStatus)) {
                      describeText = `Hóa đơn <${row.serial} - ${row.no}> đã lập thông báo sai sót với hình thức xử lý là ${errAnnouceTypeText} nhưng chưa gửi CQT. Bạn cần hoàn thành TBSS này.`
                      dispatch(showNotification({ describeText }))
                      return
                    }
                  }
                  // đã lập hóa đơn thay thế, điều chỉnh
                  if (row.Related?.at(0)) {
                    let relatedType = row.Related.at(0).type
                    if (![1, 2].includes(relatedType)) {
                      ToastHelper.showError(
                        'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                      )
                      return
                    }

                    if (relatedType == 1) {
                      let describeText = `Hóa đơn <${row.serial} - ${
                        row.no
                      }> đã được lập hóa đơn Thay thế số <${row.Related.serial} - ${
                        row.Related.no ?? 'Chưa cấp số'
                      }> . Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (Thay thế).`
                      dispatch(showNotification({ describeText }))
                      return
                    }
                    // show modal confirm
                    setActiveInvoice(row)
                    setIsInvoiceAdjust(true)
                    return
                  }

                  // Trường hợp chưa lập tbss
                  if (row.errAnnouceType == 0) {
                    // kiểm tra xem có lập biên bản hủy không
                    if (row.reportType == 'CANCEL') {
                      let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản hủy. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (hủy).`
                      dispatch(showNotification({ describeText }))
                      return
                    }
                  }
                  setActiveInvoice(row)
                  dispatch(
                    setModalsState({
                      modalName: 'selectAdjustInvoiceMethod',
                      status: true,
                    }),
                  )
                }
                if (!isCreateAdjustedInvoice)
                  dispatch(
                    setModalsState({
                      modalName: 'noRight',
                      status: true,
                    }),
                  )
              },
            },
          (row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
            row?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT) &&
            (row?.type === 0 ||
              row?.type === 1 ||
              row?.type === 3 ||
              row?.type === 4) && {
              label: 'Thay thế hoá đơn',
              icon: <IconAction className="fa-light fa-file-minus" />,
              onClick: () => {
                if (isCreateReplacementInvoice) {
                  // đã lập thông báo huỷ, điều chỉnh
                  if (![0, 3, 4].includes(row.errAnnouceType)) {
                    let describeText
                    if ([3, 5].includes(row?.invoiceErrAnnouceStatus)) {
                      describeText = `Hóa đơn <${row.serial} - ${
                        row.no
                      }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                        row.errAnnouceType,
                      )}. Bạn không được phép thay thế hóa đơn này.`
                      dispatch(showNotification({ describeText }))
                      return
                    }
                    if ([2, 6].includes(row?.invoiceErrAnnouceStatus)) {
                      describeText = `Hóa đơn <${row.serial} - ${
                        row.no
                      }> đã lập thông báo sai sót với hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                        row.errAnnouceType,
                      )} nhưng chưa gửi CQT. Bạn cần hoàn thành TBSS này.`
                      dispatch(showNotification({ describeText }))
                      return
                    }
                  }
                  if (row.type === INVOICE_TYPE.CANCELLED) {
                    navigate(
                      `/hoa-don-dau-ra/xu-ly-hoa-don/phieu-xuat-kho/thay-the/${row?.invoiceId}`,
                    )
                    return
                  }
                  if (row.type === INVOICE_TYPE.REPLACED) {
                    if (row.Related?.at(0)) {
                      let describeText = `Hóa đơn <${row.serial} - ${
                        row.no
                      }> đã được lập hóa đơn Thay thế số <${
                        row.Related.at(0).serial
                      } - ${
                        row.Related.at(0).no ?? 'Chưa câp số'
                      }>. Bạn không thể tiếp tục lập hoá đơn thay thế cho hoá đơn này.`
                      dispatch(showNotification({ describeText }))
                      return
                    }
                    navigate(
                      `/hoa-don-dau-ra/xu-ly-hoa-don/phieu-xuat-kho/thay-the/${row?.invoiceId}`,
                    )
                    return
                  }
                  // Trường hợp chưa lập tbss
                  if (row.errAnnouceType == 0) {
                    // đã lập hóa đơn thay thế, điều chỉnh
                    if (row.Related?.at(0)) {
                      let relatedType = row.Related.at(0).type
                      if (![1, 2].includes(relatedType)) {
                        ToastHelper.showError(
                          'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                        )
                        return
                      }

                      let relatedTypeVNese =
                        relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'
                      let describeText = `Hóa đơn <${row.serial} - ${
                        row.no
                      }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                        row.Related.at(0).serial
                      } - ${
                        row.Related.at(0).no ?? 'Chưa câp số'
                      }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`
                      dispatch(showNotification({ describeText }))
                      return
                    }

                    // kiểm tra xem có lập biên bản hủy không
                    // if (row.reportType == 'CANCEL') {
                    //   let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản hủy. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (hủy).`
                    //   dispatch(showNotification({ describeText }))
                    //   return
                    // }
                  }
                  setActiveInvoice(row)
                  dispatch(
                    setModalsState({
                      modalName: 'selectReplacementMethod',
                      status: true,
                    }),
                  )
                }
                if (!isCreateReplacementInvoice)
                  dispatch(
                    setModalsState({
                      modalName: 'noRight',
                      status: true,
                    }),
                  )
              },
            },
          (row?.issueStatus == INVOICE_STATUS.AUTHORITY_ACCEPT ||
            row?.issueStatus == INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
            [0, 1, 3].includes(row?.type) &&
            (row?.reportStatus == 1 ||
              [1, 2, 4, 6].includes(row?.invoiceErrAnnouceStatus)) && {
              label: 'Huỷ hoá đơn',
              icon: <IconAction className="fa-light fa-file-slash" />,
              onClick: () => {
                if (isCreateCancelInvoice) {
                  if (![0, 1, 4].includes(row.errAnnouceType)) {
                    let describeText = `Hóa đơn <${row.serial} - ${
                      row.no
                    }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                      row.errAnnouceType,
                    )}. Bạn không được phép hủy hóa đơn này.`
                    dispatch(showNotification({ describeText }))
                    return
                  }

                  // Trường hợp chưa lập tbss
                  if (row.errAnnouceType == 0) {
                    // đã lập hóa đơn thay thế, điều chỉnh
                    if (row.Related?.at(0)) {
                      let relatedType = row.Related.at(0).type
                      if (![1, 2].includes(relatedType)) {
                        ToastHelper.showError(
                          'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                        )
                        return
                      }

                      let relatedTypeVNese =
                        relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'
                      let describeText = `Hóa đơn <${row.serial} - ${
                        row.no
                      }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                        row.Related.serial
                      } - ${
                        row.Related.no ?? 'Chưa câp số'
                      }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`
                      dispatch(showNotification({ describeText }))
                      return
                    }

                    // kiểm tra xem có lập biên bản điều chỉnh không
                    if (row.reportType) {
                      if (row.reportType == 'ADJUST') {
                        let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản điều chỉnh. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (điều chỉnh).`
                        dispatch(showNotification({ describeText }))
                        return
                      }
                    }
                  }

                  setActiveInvoice(row)
                  dispatch(
                    setModalsState({
                      modalName: 'selectCancelationMethod',
                      status: true,
                    }),
                  )
                }
                if (!isCreateCancelInvoice)
                  dispatch(
                    setModalsState({
                      modalName: 'noRight',
                      status: true,
                    }),
                  )
              },
            },
          { type: 'divider' },
          row?.issueStatus !== INVOICE_STATUS.NOT_SENT_TO_AUTHORITY &&
            row?.type !== INVOICE_TYPE.DELETED && {
              label: 'Xem lịch sử',
              icon: <IconAction className="fa-light fa-history" />,
              onClick: () => {
                setActiveInvoice(row)
                dispatch(
                  setModalsState({
                    modalName: 'transmission',
                    status: true,
                  }),
                )
              },
            },
          row?.type !== INVOICE_TYPE.DELETED && {
            label: 'Đã thanh toán',
            icon: (
              <IconAction
                className={`${
                  row?.isPayment
                    ? 'fa-light fa-check text-success'
                    : 'fa-light fa-square text-black'
                }`}
              />
            ),
            onClick: () =>
              updateInvoicePaidStatus({
                invoiceId: row.invoiceId,
                isPaid: !row.isPayment,
              }),
          },
          [
            INVOICE_STATUS.AUTHORITY_ACCEPT,
            INVOICE_STATUS.AUTHORITY_DENY,
            INVOICE_STATUS.AUTHORITY_CODE_GIVEN,
            INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
          ].includes(row?.issueStatus) && {
            label: 'Chuyển thành hóa đơn giấy',
            icon: <IconAction className={'fa-light fa-paper-plane-alt'} />,
            onClick: () =>
              setConvertPaperModalConfig({
                lookupCode: row?.lookupCode,
                show: true,
              }),
          },
        ]}
      />

      {/* Các thể loại Modal */}
      <ModalConfirm
        show={hsmSignModalConfig.show}
        setShow={(value) =>
          setHSMSignModalConfig((old) => ({ ...old, show: value }))
        }
        header="Ký số HSM"
        content="Chương trình sẽ thực hiện ký số HSM. Bạn chắc chắn vẫn ký?"
        handleConfirm={() =>
          hsmSignInvoiceMutation(hsmSignModalConfig.invoiceId)
        }
        runWhenClosedFunc={() =>
          setHSMSignModalConfig((old) => ({ ...old, invoiceId: null }))
        }
      />

      <ModalConfirm
        header="Hoá đơn điều chỉnh"
        content="Hoá đơn này đã được lập hoá đơn điều chỉnh. Bạn có muốn tiếp tục lập hoá
      đơn điều chỉnh cho hoá đơn này không"
        show={isInvoiceAdjust}
        setShow={setIsInvoiceAdjust}
        handleConfirm={(e) => {
          e.preventDefault()
          setIsInvoiceAdjust(false)
          navigate(
            `/hoa-don-dau-ra/xu-ly-hoa-don/phieu-xuat-kho/dieu-chinh/${activeInvoice?.invoiceId}`,
          )
        }}
      />
      <ModalConvertPaper
        showModalConvertPaper={convertPaperModalConfig.show}
        setShowModalConvertPaper={(value) =>
          setConvertPaperModalConfig((pre) => ({ ...pre, show: value }))
        }
        handleConvertPaper={handleConvertPaper}
      />

      <ModalConfirm
        show={reIssueModalConfig.show}
        setShow={(value) =>
          setReIssueModalConfig((old) => ({ ...old, show: value }))
        }
        header="Phát hành lại hóa đơn"
        content="Chương trình sẽ thực hiện phát hành lại hóa đơn. Bạn chắc chắn muốn phát hành lại?"
        handleConfirm={() => reIssueInvoice(reIssueModalConfig.invoiceId)}
        runWhenClosedFunc={() =>
          setReIssueModalConfig((old) => ({ ...old, invoiceId: null }))
        }
      />

      <ModalConfirm
        show={reSignHSMModalConfig.show}
        setShow={(value) =>
          setReSignHSMModalConfig((old) => ({ ...old, show: value }))
        }
        header="Phát hành lại hóa đơn sử dụng cks HSM"
        content="Chương trình sẽ thực hiện phát hành lại hóa đơn sử dụng cks HSM. Bạn chắc chắn muốn phát hành lại?"
        handleConfirm={() => reSignHSM(reSignHSMModalConfig.invoiceId)}
        runWhenClosedFunc={() =>
          setReSignHSMModalConfig((old) => ({ ...old, invoiceId: null }))
        }
      />
      <CloneMultiInvoiceModal
        show={modalStates.cloneMultiInvoiceModal}
        type="hd"
        invoiceId={cloneMultiInvoiceModal.invoiceId}
        onOpenChange={(value) =>
          dispatch(
            setModalsState({
              modalName: 'cloneMultiInvoiceModal',
              status: value,
            }),
            setCloneMultiInvoiceModal({
              ...cloneMultiInvoiceModal,
              show: value,
            }),
          )
        }
        reloadList={refetchListInvoice}
      ></CloneMultiInvoiceModal>
    </>
  )
})
export default TableActions
