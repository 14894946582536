// import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import ticketApi from 'api/ticketApi'
import dayjs from 'dayjs'
import AdjustReplaceTicketModal from 'features/HandleTicket/Components/Modals/AdjustReplaceTicketModal'
import { useGetAccount } from 'features/Report/screens/ReportListTicketScreen/queries'
import {
  setClearRows,
  setModalsState,
  setSelectedTicket,
} from 'features/Ticket/ticketSlice'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import CancelInvoiceModal from 'general/components/Modal/CancelInvoiceModal'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ModalSelectAdjustInvoiceMethod from 'general/components/Modal/ModalSelectAdjustInvoiceMethod'
import ModalSelectCancellationMethod from 'general/components/Modal/ModalSelectCancellationMethod'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import ModalUploadInvoiceExcel from 'general/components/Modal/ModalUploadInvoiceExcel'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import {
  TICKET_GRANTED_STATUS,
  TICKET_STATUS,
  TYPE_TICKET,
} from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useExportById from 'hooks/Mutations/useExportById'
import useGetTicketSerial from 'hooks/Queries/useGetTicketSerial'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _, { includes } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ModalSendBulkTicket from './components/Modals/ModalSendBulkTicket'
import SendTicketToCustomerModal from './components/Modals/SendTicketToCustomerModal'
import {
  useMutationCancelTicket,
  useMutationDownloadTicket,
  useMutationPrintPdf,
  useMutationSendEmail,
  useQueryListIssuedTicket,
} from './queries'

import { ticketCheckPointQueries } from 'features/Category/screens/CheckTicketPortalScreen/queries'
import './styles.scss'
import ToastHelper from 'general/helpers/ToastHelper'
import ModalSendFromPOS from 'features/Invoice/components/modals/SendFromPOS'
import ModalListInvoiceFromPOS from 'features/Invoice/components/modals/ModalListInvoiceFromPOS'
import InvoiceHelper from 'general/helpers/InvoiceHelper'

function ListIssuedTicket({ classify = 'HD' }) {
  // MARK --- Parmas: ---
  const dispatch = useAppDispatch()
  const { selectedTickets, toggledClearRows } = useAppSelector(
    (state) => state.ticket,
  )

  const {
    selectAdjustInvoiceMethod,
    selectCancelationMethod,
    selectReplacementMethod,
    adjustReplaceTicket,
    transmission,
    noRight,
    cancel,
    upload,
    sentMail,
    bulkTicketMail,
    sentFromPos,
    listInvoiceFromPOS,
  } = useAppSelector((state) => state.ticket.modalStates)

  const [activeTicket, setActiveTicket] = useState(null)
  const [typeTicket, setTypeTicket] = useState(null)

  const [filters, setFilters] = useFilter(
    classify === 'MTT' ? 'issuedTicketListFromPOS' : 'issuedTicketList',
    classify === 'MTT'
      ? 'gFilterListIssuedTicketFromPOS'
      : 'gFilterListIssuedTicket',
  )
  // const [filters, setFilters] = useFilter(
  //   'issuedTicketList',
  //   'gFilterListIssuedTicket',
  // )

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')

  const ticketSerialQuery = useGetTicketSerial({
    includeAllOption: true,
    disabled: false,
    classify,
  })

  const { data: accounts } = useGetAccount({
    page: 0,
    limit: 50,
  })

  const {
    data: ticketListData,
    isLoading: isGettingListData,
    refetch: refetchTicketList,
    isFetching,
  } = useQueryListIssuedTicket(debouncedSearch, {
    ...filters,
    classify,
  })

  const { exportFunc } = useExportById(
    (ticketId) => ticketApi.exportById(ticketId),
    'vé đã xuất.pdf',
    'application/pdf',
  )

  const { mutate: sendTicketMutation } = useMutationSendEmail()
  const { mutate: downloadTicket } = useMutationDownloadTicket()
  const { mutate: cancelTicket } = useMutationCancelTicket()
  const { mutate: printPdfTicket } = useMutationPrintPdf()

  const renderTicketType = (type) => {
    switch (type) {
      case TICKET_STATUS.NEW:
        return <div className="badge badge-light-success">Vé mới</div>
      case TICKET_STATUS.ADJUST:
        return <div className="badge badge-light-info">Điều chỉnh</div>
      case TICKET_STATUS.REPLACE:
        return <div className="badge badge-light-primary">Thay thế</div>
      case TICKET_STATUS.CANCELLED:
        return <div className="badge badge-light-danger">Đã huỷ</div>
      case TICKET_STATUS.REPLACED:
        return <div className="badge badge-light-warning">Đã bị thay thế</div>
      case TICKET_STATUS.ADJUSTED:
        return <div className="badge badge-light-warning">Đã bị điều chỉnh</div>

      default:
        return <div className="badge badge-light-success">Vé mới</div>
    }
  }

  const renderTicketRelease = (type) => {
    switch (type) {
      // case TICKET_GRANTED_STATUS.GRANTED:
      //   return <div className="badge badge-light-success">Đã cấp mã</div>
      case TICKET_GRANTED_STATUS.WAITING:
        return <div className="badge badge-light-primary">CQT đang xử lý</div>
      case TICKET_GRANTED_STATUS.DENIED:
        return <div className="badge badge-light-danger">CQT từ chối</div>
    }
  }

  const cols = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<ReturnTicketData>[]}  */
    const arr = [
      {
        name: 'Ký hiệu',
        minWidth: '100px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        name: 'Ngày xuất vé',
        minWidth: '120px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {dayjs(row?.dateRelease).format('L')}
            </p>
          )
        },
      },
      {
        name: 'Số vé',
        minWidth: '95px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.no}
            </p>
          )
        },
      },
      {
        name: 'Mã CQT',
        center: true,
        omit: false,
        minWidth: '200px',
        cell: (row) => {
          const { taxAuthorityCode, typeRelease } = row
          if (taxAuthorityCode) {
            return (
              <p
                data-tag="allowRowEvents"
                className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
              >
                {taxAuthorityCode}
              </p>
            )
          } else {
            return (
              <div className="w-100 d-flex align-items-center justify-content-center">
                {renderTicketRelease(typeRelease)}
              </div>
            )
          }
        },
      },
      {
        name: 'Tuyến đường',
        minWidth: '150px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.nameRoute}
            </p>
          )
        },
      },
      {
        name: 'Bến đi',
        minWidth: '100px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.routeStart}
            </p>
          )
        },
      },
      {
        name: 'Bến đến ',
        minWidth: '100px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.routeEnd}
            </p>
          )
        },
      },
      {
        name: 'Số ghế',
        minWidth: '100px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.noSeatCar}
            </p>
          )
        },
      },
      {
        name: 'Số xe',
        minWidth: '120px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.noCar}
            </p>
          )
        },
      },
      {
        name: 'Ngày khởi hành',
        center: true,
        omit: false,
        minWidth: '140px',
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {row?.departureDateTime &&
                dayjs(row?.departureDateTime).format('L')}
              {/* {row?.departureDateTime} */}
            </p>
          )
        },
      },
      {
        name: 'Giờ khởi hành',
        center: true,
        omit: false,
        minWidth: '120px',
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {row?.departureDateTime &&
                dayjs(row?.departureDateTime).format('HH:mm')}
            </p>
          )
        },
      },
      {
        name: 'Tổng tiền',
        minWidth: '130px',
        center: true,
        omit: false,

        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {Utils.formatNumber(row?.totalPrice)}
            </p>
          )
        },
      },
      {
        name: 'Trạng thái vé',
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <div className="w-100 align-items-center justify-content-center">
              {renderTicketType(row?.typeTicket)}
            </div>
          )
        },
      },
      {
        name: 'Gửi CQT',
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          const { color, text } = InvoiceHelper.renderSendTaxStatus(
            row.sendTaxStatus,
          )
          return <div className={`badge badge-light-${color}`}>{text}</div>
        },
      },
      {
        name: 'Xuất lại vé',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.typeRerelease}
            </p>
          )
        },
      },
      {
        name: 'Người xuất vé',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.namePersonCreate}
            </p>
          )
        },
      },
      {
        name: 'Thời gian xuất',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {dayjs(row?.dateRelease).format('HH:mm')}
            </p>
          )
        },
      },
      {
        name: 'Số lần in',
        center: true,
        omit: false,
        minWidth: '100px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.noPrint}
            </p>
          )
        },
      },
      {
        name: 'Mã tra cứu',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.lookupCode}
            </p>
          )
        },
      },
      {
        name: 'Mã thiết bị',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.serialDevice}
            </p>
          )
        },
      },
      {
        name: 'Tình trạng gửi vé',
        center: true,
        grow: 1,
        minWidth: '100px',
        selector: ({ isSent }) => (
          <IconAction
            style={
              isSent !== 0
                ? {
                    '--fa-primary-color': '#fff',
                    '--fa-secondary-color': '#50cd89',
                    '--fa-secondary-opacity': 1,
                  }
                : {}
            }
            className={Utils.cn(
              'fa-duotone',
              isSent === 0
                ? 'fa-do-not-enter text-light-dark'
                : 'fa-circle-check',
            )}
          />
        ),
      },
      {
        name: 'Hành động',
        center: true,
        disabled: true,
        grow: 2,
        minWidth: '150px',
        cell: (row) => (
          <Space align="center">
            <TableAction
              icon={<IconAction className="fa-light fa-eye" />}
              titleActionText="Xem"
              onClick={() => {
                exportFunc(row.ticketId)
              }}
            />

            <TableAction
              icon={<IconAction className="fa-light fa-file-download" />}
              titleActionText="Tải xuống"
              onClick={() => {
                downloadTicket({
                  ticketIds: [row.ticketId],
                })
              }}
            />

            <TableAction
              icon={<IconAction className="fa-light fa-envelope" />}
              titleActionText="Gửi vé cho kh"
              onClick={() => {
                setActiveTicket(row)
                dispatch(
                  setModalsState({ modalName: 'sentMail', status: true }),
                )
              }}
            />
            <TableAction
              type="dropdown"
              icon={<IconAction className="fa-light fa-ellipsis" />}
              items={[
                {
                  label: 'Xem lịch sử',
                  icon: <IconAction className="fa-light fa-history" />,
                  onClick: () => {
                    setActiveTicket(row)
                    dispatch(
                      setModalsState({
                        modalName: 'transmission',
                        status: true,
                      }),
                    )
                  },
                },
                row.typeRelease === TICKET_GRANTED_STATUS.GRANTED &&
                  (row.typeTicket === TICKET_STATUS.NEW ||
                    row.typeTicket === TICKET_STATUS.REPLACE) && {
                    label: 'Huỷ vé',
                    icon: <IconAction className="fa-light fa-file-slash" />,
                    onClick: () => {
                      setActiveTicket(row)
                      dispatch(
                        setModalsState({
                          modalName: 'selectCancelationMethod',
                          status: true,
                        }),
                      )
                    },
                  },
                row.typeRelease === TICKET_GRANTED_STATUS.GRANTED &&
                  (row.typeTicket === TICKET_STATUS.NEW ||
                    row.typeTicket === TICKET_STATUS.REPLACE ||
                    row.typeTicket === TICKET_STATUS.CANCELLED) &&
                  row.invoice_template?.category_ticket?.typePrice === 2 && {
                    label: 'Thay thế vé',
                    icon: <IconAction className="fa-light fa-file-minus" />,
                    onClick: () => {
                      setActiveTicket(row)
                      if (row.typeTicket === TICKET_STATUS.CANCELLED) {
                        setTypeTicket(TYPE_TICKET.REPLACE)
                        dispatch(
                          setModalsState({
                            modalName: 'adjustReplaceTicket',
                            status: true,
                          }),
                        )
                        return
                      }
                      dispatch(
                        setModalsState({
                          modalName: 'selectReplacementMethod',
                          status: true,
                        }),
                      )
                    },
                  },
                row.typeRelease === TICKET_GRANTED_STATUS.GRANTED &&
                  (row.typeTicket === TICKET_STATUS.NEW ||
                    row.typeTicket === TICKET_STATUS.ADJUSTED) &&
                  row.invoice_template?.category_ticket?.typePrice === 2 && {
                    label: 'Điều chỉnh vé',
                    icon: <IconAction className="fa-light fa-pen-to-square" />,
                    onClick: () => {
                      setTypeTicket(TYPE_TICKET.ADJUST)
                      setActiveTicket(row)
                      dispatch(
                        setModalsState({
                          modalName: 'selectAdjustInvoiceMethod',
                          status: true,
                        }),
                      )
                    },
                  },
              ]}
            />
          </Space>
        ),
      },
    ]
    return arr
  }, [exportFunc, downloadTicket, dispatch])

  const dynamicColumns = useAppDnD(cols)

  const { data: listTicketPortal } = useQuery({
    ...ticketCheckPointQueries.lists(),
    select: (res) => {
      return _.concat(
        { serialDevice: null, namePointCheckTicket: 'Tất cả' },
        res?.namePointCheckTickets,
      ).map((namePointCheckTicket) => ({
        label: namePointCheckTicket?.namePointCheckTicket,
        value: namePointCheckTicket?.serialDevice,
      }))
    },
  })
  const defaultValues = useMemo(
    () =>
      [
        {
          label: 'Ký hiệu',
          alias: 'Ký hiệu',
          formName: 'serials',
          options: ticketSerialQuery?.data,
          value: null,
          colSpan: 24,
          multiselect: true,
        },
        {
          label: 'Người xuất vé',
          alias: 'Người xuất vé',
          formName: 'accountIds',
          multiselect: true,
          colSpan: classify == 'MTT' ? null : 24,
          options: accounts,
          value: null,
        },
        {
          label: 'Trạng thái vé',
          alias: 'Trạng thái vé',
          formName: 'typeTickets',
          multiselect: true,
          options: SelectOptions.ticketStatus,
          value: SelectOptions.ticketStatus[0].value,
        },
        {
          label: 'Trạng thái cấp mã',
          alias: 'Trạng thái cấp mã',
          formName: 'typeRelease',
          options: SelectOptions.ticketGrantedStatus,
          value: SelectOptions.ticketGrantedStatus[0].value,
          multiselect: true,
        },
        {
          label: 'Trạng thái gửi vé cho KH',
          alias: 'Trạng thái gửi vé cho KH',
          formName: 'isSent',
          options: SelectOptions.isSent,
          value: SelectOptions.isSent[0].value,
        },
        {
          label: 'Cổng soát vé',
          alias: 'Cổng soát vé',
          formName: 'serialDevices',
          options: listTicketPortal,
          value: null,
          multiselect: true,
        },
        classify == 'MTT'
          ? {
              label: 'Trạng thái gửi CQT',
              alias: 'Trạng thái gửi CQT',
              formName: 'sendTaxStatus',
              options: SelectOptions.sendTaxStatus,
              value: null,
            }
          : null,
      ].filter((i) => i),
    [accounts, listTicketPortal, ticketSerialQuery?.data, classify],
  )

  const renderActionGroup = useCallback(() => {
    const buttonGroups = [
      {
        text: 'Gửi',
        icon: 'fa-light fa-envelope',
        color: '#0891B2',
        onClick: () => {
          dispatch(
            setModalsState({
              modalName: 'bulkTicketMail',
              status: true,
            }),
          )
        },
      },
      {
        text: 'Tải về',
        icon: 'fa-light fa-file-download',
        color: '#059669',
        onClick: () => {
          downloadTicket({
            ticketIds: selectedTickets.map((ticket) => ticket.ticketId),
          })
        },
      },
      {
        text: 'In',
        icon: 'fa-light fa-print',
        color: '#4F46E5',
        onClick: () => {
          if (selectedTickets.length > 5) {
            ToastHelper.showError(
              'Số lượng vé in không vượt quá 5. Vui lòng thử lại',
            )
          } else {
            printPdfTicket({
              ticketIds: selectedTickets.map((t) => t.ticketId),
            })
          }
        },
      },
      {
        text: 'Huỷ chọn',
        icon: 'fa-light fa-times',
        type: 'default',
        danger: true,
        onClick: () => {
          dispatch(setSelectedTicket([]))
          dispatch(setClearRows())
        },
      },
    ]

    return (
      <div
        className={Utils.cn(
          selectedTickets.length > 0 ? 'd-flex' : 'd-none',
          'flex-row justify-content-end align-items-center',
        )}
        style={{ gap: '10px' }}
      >
        {buttonGroups.map((bg) => (
          <CustomAntButton
            key={bg.text}
            text={bg.text + ' (' + selectedTickets.length + ')'}
            fontSizeText={12}
            iconCn={bg.icon}
            color={bg.color}
            antProps={{
              disabled: bg.disabled,
              type: bg.type ?? 'primary',
              className: Utils.cn(bg.className, bg.disabled ? 'disabled' : ''),
              onClick: bg.onClick,
            }}
          />
        ))}
      </div>
    )
  }, [dispatch, downloadTicket, printPdfTicket, selectedTickets])

  return (
    <ContentContainer wrapperClassName={'list-issued-ticket'}>
      <ContentContainer.Header
        titleContent={`Danh sách vé ${classify == 'MTT' ? 'máy tính tiền' : ''} đã xuất`}
        description={
          classify == 'MTT' ? (
            <div className="d-flex justify-content-center align-items-center">
              <i className="fa-solid fa-message-question text-primary fa-2x mr-1" />
              <span className="fw-medium fs-6 fst-italic">
                Vé khởi tạo từ máy tính tiền cần gửi đến CQT trong ngày
              </span>
            </div>
          ) : (
            Utils.cn('Tổng:', Utils.formatNumber(ticketListData?.total), 'vé')
          )
        }
        toolBar={
          <Space>
            <CustomAntButton
              text="Upload excel"
              variant="success"
              antProps={{
                type: 'primary',
                onClick: () =>
                  dispatch(
                    setModalsState({ modalName: 'upload', status: true }),
                  ),
              }}
              iconCn={'fa-regular fa-file-arrow-up text-white'}
            />
            {classify == 'MTT' ? (
              <CustomAntButton
                text={'Gửi dữ liệu MTT đến CQT'}
                iconCn="far fa-file-export"
                antProps={{
                  type: 'default',
                  onClick: () => {
                    dispatch(
                      setModalsState({
                        modalName: 'sentFromPos',
                        status: true,
                      }),
                    )
                  },
                }}
              />
            ) : (
              <CustomAntButton
                iconCn={Utils.cn(
                  'fa-regular fa-rotate',
                  isFetching && 'fa-spin',
                )}
                isLoading={isGettingListData}
                antProps={{
                  type: 'default',
                  title: 'Kiểm tra',
                  className: 'p-3',
                  shape: 'round',
                  onClick: () => refetchTicketList(),
                  // checkMessages(_.pick(filterParams, ['startDate', 'endDate'])),
                }}
                customStyle={{
                  minWidth: 'fit-content',
                }}
              />
            )}

            <AppDnD
              defaultColumns={cols}
              localStorageName={PreferenceKeys.listIssusedTicket}
            />
          </Space>
        }
      >
        {renderActionGroup()}
        <Space
          wrap
          className={Utils.cn(selectedTickets?.length > 0 ? 'd-none' : '')}
        >
          <CustomAntInput
            borderRadius="sm"
            type="search"
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            menuPosition="bottomLeft"
            defaultValues={defaultValues}
            onApply={setFilters}
            savedKey={
              classify === 'HD'
                ? 'gFilterListIssuedTicket'
                : 'gFilterListIssuedTicketFromPOS'
            }
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={ticketListData?.rows}
          isLoading={isGettingListData}
          isClickable
          isSelectable
          handleDoubleClickRow={(row) => exportFunc(row.ticketId)}
          pagination={{
            currentPage: filters.page,
            total: ticketListData?.total,
            rowsPerPage: filters.limit,
            onChangePage: (page) => setFilters({ page }),
            onChangeRowsPerPage: (limit) => setFilters({ limit, page: 1 }),
          }}
          selectedRows={{
            clearSelectedRows: toggledClearRows,
            handleSelectedRows: ({ selectedRows }) => {
              dispatch(setSelectedTicket(selectedRows))
            },
            selectableRowSelected: (row) => includes(selectedTickets, row),
          }}
        />
      </ContentContainer.Body>

      <ModalDataTransmissionLog
        show={transmission}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'transmission',
              status: false,
            }),
          )
        }
        invoice={activeTicket}
        isInvoice={false}
      />

      <ModalUnauthorized
        show={noRight}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'noRight',
              status,
            }),
          )
        }
      />
      <ModalSelectCancellationMethod
        show={selectCancelationMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',
              status: status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập thông báo hóa đơn điện tử có sai sót gửi cơ quan thuế và lập biên bản điện tử để thỏa thuận với người mua trước khi hủy vé.."
        cancelInvoiceSelect={
          [TICKET_STATUS.NEW, TICKET_STATUS.REPLACE].includes(
            activeTicket?.typeTicket,
          )
            ? () => {
                dispatch(
                  setModalsState({
                    modalName: 'selectCancelationMethod',
                    status: false,
                  }),
                )
                dispatch(
                  setModalsState({
                    modalName: 'cancel',
                    status: true,
                  }),
                )
              }
            : null
        }
      />
      <ModalSelectAdjustInvoiceMethod
        show={selectAdjustInvoiceMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectAdjustInvoiceMethod',
              status: status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập biên bản điện tử để thỏa thuận với người mua trước khi lập vé điều chỉnh."
        adjustInvoiceSelect={
          [TICKET_STATUS.NEW, TICKET_STATUS.REPLACE].includes(
            activeTicket?.typeTicket,
          )
            ? () => {
                dispatch(
                  setModalsState({
                    modalName: 'selectAdjustInvoiceMethod',
                    status: false,
                  }),
                )
                dispatch(
                  setModalsState({
                    modalName: 'adjustReplaceTicket',
                    status: true,
                  }),
                )
              }
            : null
        }
      />
      <CancelInvoiceModal
        show={cancel}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'cancel',
              status: false,
            }),
          )
        }
        title="Huỷ vé"
        ticket={activeTicket}
        isInvoice={false}
        buttonAgreeOnClick={(formikValues) =>
          cancelTicket({ ...formikValues, ticketId: activeTicket?.ticketId })
        }
      />
      <ModalConfirm
        header="Xác nhận"
        content={`Để lập được vé thay thế cho vé <${activeTicket?.serial} - ${activeTicket?.no}> bạn cần thực hiện hủy vé này trước. Bạn có muốn thực hiện hủy vé không?`}
        show={selectReplacementMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectReplacementMethod',
              status,
            }),
          )
        }
        handleConfirm={() => {
          dispatch(
            setModalsState({
              modalName: 'selectReplacementMethod',
              status: false,
            }),
          )
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',
              status: true,
            }),
          )
        }}
      />
      <AdjustReplaceTicketModal
        toggleModalState={(status) =>
          dispatch(setModalsState({ modalName: 'adjustReplaceTicket', status }))
        }
        open={adjustReplaceTicket}
        title={
          typeTicket === TYPE_TICKET.ADJUST
            ? 'Xuất vé điều chỉnh'
            : 'Xuất vé thay thế'
        }
        ticket={activeTicket}
        typeTicket={typeTicket}
      />

      <ModalUploadInvoiceExcel
        show={upload}
        setShow={(status) => {
          dispatch(
            setModalsState({
              modalName: 'upload',
              status,
            }),
          )
        }}
      />

      <SendTicketToCustomerModal
        open={sentMail}
        onOpenChange={(status) =>
          dispatch(setModalsState({ modalName: 'sentMail', status }))
        }
        title="Gửi vé cho khách hàng"
        ticketData={activeTicket}
        handleApplyChanges={(values) =>
          sendTicketMutation({
            ...values,
            ticketIds: [activeTicket?.ticketId],
          })
        }
      />

      <ModalSendBulkTicket
        open={bulkTicketMail}
        onOpenChange={(status) =>
          dispatch(
            setModalsState({
              modalName: 'bulkTicketMail',
              status,
            }),
          )
        }
        title="Gửi vé cho khách hàng"
        tickets={selectedTickets}
        onApply={(values) =>
          sendTicketMutation({
            ...values,
            ticketIds: selectedTickets.map((ticket) => ticket.ticketId),
          })
        }
      />

      <ModalSendFromPOS
        invoiceType="TICKET"
        show={sentFromPos}
        onOpenChange={(status) => {
          dispatch(
            setModalsState({
              modalName: 'sentFromPos',
              status,
            }),
          )
        }}
        refetchListInvoice={() => {}}
      />

      <ModalListInvoiceFromPOS
        invoiceType="TICKET"
        show={listInvoiceFromPOS}
        onOpenChange={(status) => {
          dispatch(
            setModalsState({
              modalName: 'listInvoiceFromPOS',
              status,
            }),
          )
          dispatch(
            setModalsState({
              modalName: 'sentFromPos',
              status: true,
            }),
          )
        }}
      />
    </ContentContainer>
  )
}

export default ListIssuedTicket
