import _ from 'lodash'
import QueryString from 'qs'
import axiosClient from './axiosClient'

const templateInvoiceApi = {
  getTemplates: (params, signal) => {
    const clonedParams = _.clone(params)
    clonedParams.page = clonedParams.page > 0 ? clonedParams.page - 1 : 0
    const url = '/template'
    return axiosClient.get(url, {
      params: {
        ...clonedParams,
        isOrder: true,
      },
      signal,
    })
  },

  createTemplate: (params) => {
    const url = `/template`
    let formData = new FormData()

    for (let [key, value] of Object.entries(params)) {
      if (['region', 'calcConfig', 'extendFields'].includes(key)) {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    }

    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  updateTemplate: (invoiceTemplateId, params) => {
    const url = `/template/${invoiceTemplateId}`
    let formData = new FormData()

    for (let [key, value] of Object.entries(params)) {
      if (['region', 'calcConfig', 'extendFields'].includes(key)) {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    }

    return axiosClient.put(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  deleteTemplate: (invoiceTemplateId) => {
    const url = `/template/${invoiceTemplateId}`

    return axiosClient.delete(url)
  },

  getSystemTemplatesByCategoryIds: (categoryIds) => {
    const url = `/template/system`

    return axiosClient.get(url, {
      params: { categoryIds },
      paramsSerializer: (params) => QueryString.stringify(params),
    })
  },

  exportTemplate: (params) => {
    const url = `/template/export`

    let formData = new FormData()

    for (let [key, value] of Object.entries(params)) {
      if (key == 'region') {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    }

    return axiosClient.post(url, formData, { responseType: 'blob' })
  },

  exportTemplateById: (invoiceTemplateId, params) => {
    const url = `/template/export/${invoiceTemplateId}`

    return axiosClient.get(url, { responseType: 'blob', params })
  },

  getDetail: (templateInvoiceId) => {
    const url = `/template/${templateInvoiceId}`

    return axiosClient.get(url)
  },

  getSurrounds: () => {
    const url = '/border'

    return axiosClient.get(url)
  },

  getBackgrounds: () => {
    const url = '/background'

    return axiosClient.get(url)
  },

  getCategories: () => {
    const url = '/category'

    return axiosClient.get(url)
  },

  getTemplateSerials: (params, signal) => {
    const url = '/template/serials'
    return axiosClient.get(url, {
      params: {
        active: 1,
        ...params,
      },
      signal,
    })
  },

  getAllTemplateSerials: () => {
    const url = '/template/serials'

    return axiosClient.get(url)
  },

  getTicketSerials: (params) => {
    const url = '/template/serials'

    return axiosClient.get(url, {
      params: {
        active: 1,
        invoiceType: 'TICKET',
        categoryTicketTypePrice: params?.categoryTicketTypePrice,
        invoiceClassify: params?.classify,
      },
    })
  },
}

export default templateInvoiceApi
