import { useQuery } from '@tanstack/react-query'
import { Divider } from 'antd'
import { Space } from 'antd'
import { Flex, Typography } from 'antd'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import { setModalsState } from 'features/Invoice/invoiceSlice'
import { setModalsState as setModalsStateTicket } from 'features/Ticket/ticketSlice'
import { listInvoiceFromPosQueries } from 'features/Invoice/screens/ListInvoiceFromPOS/queries'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useRef, useState } from 'react'

/**
 *
 * @param {object} props
 * @param {boolean} props.show
 * @param {(v: boolean) => void} props.onOpenChange
 * @returns
 */
export default function ModalSendFromPOS(props) {
  const modalRef = useRef()
  const dispatch = useAppDispatch()
  const [filterParams] = useFilter(
    'listInvoiceFromPOS',
    'gFilterListInvoiceFromPOS',
  )

  const { data: queryData } = useQuery(
    listInvoiceFromPosQueries.listInvoiceMttNeedSendTax(filterParams),
  )

  const { activeSigningMethod } = useAppSelector(
    (state) => state.system.company,
  )

  const [showHsmSignModal, setShowHSMSignModal] = useState(false)

  const handleSendSummaryInvoiceToTax = () => {
    if (props.invoiceType == 'INVOICE' && !queryData.invoices.length) {
      ToastHelper.showError('Chưa có hóa đơn MTT để gửi CQT')
      return
    }

    if (props.invoiceType == 'TICKET' && !queryData.tickets.length) {
      ToastHelper.showError('Chưa có vé MTT để gửi CQT')
      return
    }

    if (activeSigningMethod == 'HSM') {
      setShowHSMSignModal(true)
      props.onOpenChange(false)
    } else {
      let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
      if (props.invoiceType == 'TICKET') {
        let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},summaryTicketSignedBySeller,${queryData.tickets
          .map(({ ticketId }) => ticketId)
          .join(';')}`

        Utils.openToolSignSummaryInvoice({
          url: urlSchema,
          accessToken,
          invoiceType: props.invoiceType,
        })
      } else {
        let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},summaryInvoiceSignedBySeller,${queryData.invoices
          .map(({ invoiceId }) => invoiceId)
          .join(';')}`

        Utils.openToolSignSummaryInvoice({ url: urlSchema, accessToken })
      }
    }
  }

  const handleHsmSignSummaryInvoice = async () => {
    props.onOpenChange(true)

    dispatch(setAppSpinning(true))
    try {
      let invoiceIds = queryData.invoices.map(({ invoiceId }) => invoiceId)
      let ticketIds = queryData.tickets.map(({ ticketId }) => ticketId)
      await invoiceApi.hsmSignSummaryInvoice({ invoiceIds, ticketIds })
      dispatch(setAppSpinning(false))

      ToastHelper.showSuccess('Gửi dữ liệu MTT đến CQT thành công')
    } catch (e) {
      dispatch(setAppSpinning(false))
      console.log(e)
    } finally {
      props.onOpenChange(false)
      props.refetchListInvoice()
    }
  }

  return (
    <>
      <DraggableModal
        ref={modalRef}
        title="Gửi dữ liệu máy tính tiền đến CQT"
        isOpen={props.show}
        onOpenChange={props.onOpenChange}
        cancelText="Hủy bỏ"
        applyText="Gửi"
        handleApplyChanges={handleSendSummaryInvoiceToTax}
        width={550}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Space
          split={<Divider type="horizontal" className="my-0" />}
          direction="vertical"
          className="bg-secondary w-100"
        >
          <Flex
            justify={'space-between'}
            align={'center'}
            className="w-100 container pt-1"
          >
            <Typography.Text>
              Số lượng hóa đơn:
              <span className="fw-bold ml-1">
                {props.invoiceType == 'INVOICE'
                  ? Utils.formatNumber(queryData?.invoices?.length)
                  : 0}
              </span>
            </Typography.Text>

            <Typography.Link
              disabled={
                props.invoiceType != 'INVOICE' ||
                queryData?.invoices?.length === 0
              }
              onClick={() => {
                dispatch(
                  setModalsState({
                    modalName: 'listInvoiceFromPOS',
                    status: true,
                  }),
                )
              }}
            >
              Chi tiết
            </Typography.Link>
          </Flex>
          <Flex
            justify={'space-between'}
            align={'center'}
            className="w-100 container pb-1"
          >
            <Typography.Text>
              Số lượng vé gửi:
              <span className="fw-bold ml-1">
                {props.invoiceType == 'TICKET'
                  ? Utils.formatNumber(queryData?.tickets?.length)
                  : 0}
              </span>
            </Typography.Text>

            <Typography.Link
              disabled={
                props.invoiceType != 'TICKET' ||
                queryData?.tickets?.length === 0
              }
              onClick={() => {
                dispatch(
                  setModalsStateTicket({
                    modalName: 'listInvoiceFromPOS',
                    status: true,
                  }),
                )
              }}
            >
              Chi tiết
            </Typography.Link>
          </Flex>
        </Space>
        <Typography.Paragraph className="container fs-6 fst-italic mt-3">
          (Bao gồm tất cả hóa đơn/vé khởi tạo từ máy tính tiền chưa được gửi đến
          CQT, kể cả hóa đơn/vé không được lập từ VietInvoice)
        </Typography.Paragraph>
      </DraggableModal>
      <ModalConfirm
        show={showHsmSignModal}
        setShow={(value) => setShowHSMSignModal(value)}
        header="Ký số HSM"
        content="Chương trình sẽ thực hiện ký số HSM. Bạn chắc chắn vẫn ký?"
        handleConfirm={handleHsmSignSummaryInvoice}
        runWhenClosedFunc={() => {
          props.onOpenChange(true)
        }}
      />
    </>
  )
}
