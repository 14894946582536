import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import UserNoFunction from 'general/components/UserNoFunction'
import AppResource from 'general/constants/AppResource'
import useCheckPermission from 'hooks/useCheckPermission'
import { useAppSelector } from 'hooks/useRedux'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import ContractManagement from './screens/ContractManagement'
import DigitalSignPreference from './screens/DigitalSignPreference'
import EnterpriseProfile from './screens/EnterpriseProfile'
import ExtendScreen from './screens/ExtendScreen'
import InvoiceApprovalProcess from './screens/InvoiceApprovalProcess/'
import ResourceManagement from './screens/ResourceManagement'
import SystemConfiguration from './screens/SystemConfiguration'
import UserManagement from './screens/UserManagement'

function System() {
  // MARK: --- Params ---
  const { t } = useTranslation()

  const user = useAppSelector((state) => state?.auth?.currentAccount)
  const {
    isLoadingPermission,
    canViewInvoiceRecords,
    canViewInfoResource,
    canViewListOptionPermission,
  } = useCheckPermission([
    {
      keyAction: 'canViewInvoiceRecords',
      permissionCode: 'INVOICE_RECORDS_VIEW_LIST',
    },
    {
      keyAction: 'canViewInfoResource',
      permissionCode: 'INFO_RESOURCES_VIEW_LIST',
    },
    {
      keyAction: 'canViewListOptionPermission',
      permissionCode: 'OPTIONS_VIEW_LIST',
    },
  ])

  return (
    <div className="container-fluid h-100 d-flex flex-column p-3">
      <Routes>
        <Route path="" element={<Navigate to="doanh-nghiep" />} />
        <Route
          path="doanh-nghiep"
          element={
            <ProtectedRoute
              isLoading={isLoadingPermission}
              permission={canViewInvoiceRecords}
            >
              <EnterpriseProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="nguoi-dung"
          element={
            <ProtectedRoute
              permission={user && user?.isAdmin}
              noPermission={UserNoFunction}
            >
              <UserManagement />
            </ProtectedRoute>
          }
        />

        <Route
          path="mua-them"
          element={
            <ProtectedRoute
              isLoading={isLoadingPermission}
              permission={canViewInfoResource}
            >
              <ExtendScreen />
            </ProtectedRoute>
          }
        />
        <Route path="hop-dong" element={<ContractManagement />} />
        <Route path="tai-nguyen" element={<ResourceManagement />} />
        <Route
          path="cau-hinh"
          element={
            <ProtectedRoute
              isLoading={isLoadingPermission}
              permission={canViewListOptionPermission}
            >
              <SystemConfiguration />
            </ProtectedRoute>
          }
        />
        <Route
          path="quy-trinh-phe-duyet-HD"
          element={<InvoiceApprovalProcess />}
        />

        <Route path="thiet-lap-ki-so/*" element={<DigitalSignPreference />} />

        <Route
          path="*"
          element={
            <Container fluid className="h-100 d-flex">
              <Row className="justify-content-center align-items-center flex-grow-1">
                <Empty
                  text={t('PageNotFound')}
                  buttonText={t('Refresh')}
                  visible={false}
                  imageEmpty={AppResource.icons.icEmptyBox}
                />
              </Row>
            </Container>
          }
        />
      </Routes>
    </div>
  )
}

export default System
