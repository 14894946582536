import { Input } from 'antd'
import DraggableModal from '../DragableModal/DraggableModal'
import { useRef } from 'react'
import FormLabel from 'general/components/Forms/FormLabel'
import { Formik } from 'formik'
import { useMutation } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import { useDispatch } from 'react-redux'
import ToastHelper from 'general/helpers/ToastHelper'
import { setModalsState } from 'features/Invoice/invoiceSlice'
// import { FormLabel } from '@mui/material'

export const CloneMultiInvoiceModal = (props) => {
  const { invoiceId, type, show, onOpenChange, reloadList } = props
  const dispatch = useDispatch()
  const cloneMultiInvoiceModal = useRef(null)
  const cloneTypes = {
    hd: 'hóa đơn',
    pxk: 'phiếu xuất kho',
    mtt: 'máy tính tiền',
    ve: 'vé',
  }
  const { mutate } = useMutation({
    mutationKey: ['cloneMutilInvoices'],
    mutationFn: async (params) => invoiceApi.cloneMutilInvoices(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSuccess: () => {
      ToastHelper.showSuccess('Nhân bản hóa đơn thành công!')
      dispatch(setAppSpinning(false))
      dispatch(
        setModalsState({
          modalName: 'cloneMultiInvoiceModal',
          status: false,
        }),
      )
      reloadList()
    },
    onError: () => dispatch(setAppSpinning(false)),
  })
  return (
    <Formik
      initialValues={{ invoiceId, quantity: null }}
      onSubmit={(values) => {
        mutate(values)
      }}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, values }) => (
        <form onSubmit={handleSubmit}>
          <DraggableModal
            title={`Nhân bản ${cloneTypes[type]} hàng loạt`}
            // description
            isOpen={show}
            onOpenChange={onOpenChange}
            handleApplyChanges={() => handleSubmit()}
            applyText="Xác nhận"
            cancelText="Hủy bỏ"
            // isLoading
            // styles
            // status
            hasCancelButton
            ref={cloneMultiInvoiceModal}
          >
            <FormLabel>{`Số lượng ${cloneTypes[type]}`}</FormLabel>
            <Input
              name="quantity"
              value={values.quantity}
              placeholder={`Nhập số lượng ${cloneTypes[type]} cần nhân bản`}
              type="number"
              onChange={handleChange}
            ></Input>
          </DraggableModal>
        </form>
      )}
    </Formik>
  )
}
