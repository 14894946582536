import { useTranslation } from 'react-i18next'
import { Result, Button, Space, Empty, Form, Menu, Dropdown } from 'antd'
import { PlusOutlined, SearchOutlined, SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ContainerFunction,
  ContainerSearch,
  ContainerTable,
  CopyOutlinedIcon,
  DeleteOutlinedIcon,
  DivFunction,
  DivListFunction,
  DivRight,
  DownloadOutlinedIcon,
  FileExcelOutlinedIcon,
  FilePdfOutlinedIcon,
  FormOutlinedIcon,
  InputCustom,
  MailOutlinedIcon,
  PrinterOutlinedIcon,
  SelectCustom,
  SendOutlinedIcon,
  SpaceCustom,
  SpanNotYetReleasedTd,
  StopOutlinedIcon,
} from 'features/TaxDeductionDocuments/styled'
import { EllipsisDiv } from 'features/Taxpayer/styled'
import { ButtonSave, TableCustom } from 'features/TaxDeduction/styled'
import Utils, {
  convertDate,
  findObjectInArray,
  padNumberWithZeros,
  removeItemByKeys,
} from 'general/utils/Utils'
import SelectOptions from 'general/constants/SelectOptions'
import { useCallback, useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { thunkFindByTempapeRreleaseTaxDeductionDocuments } from 'features/TaxDeductionDocuments/taxDeductionDocumentsSlice'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import ToastHelper from 'general/helpers/ToastHelper'
import AlertTaxDectionDocumentsUpdate from 'features/TaxDeductionDocuments/component/Alert'
import {
  thunkFindAccountingDocument,
  thunkSignHSMAccountingDocument,
  thunkRemoveAccountingDocument,
  thunkCancelAccountingDocument,
  thunkExportAccountingDocument,
  thunkSendEmailAccountingDocument,
} from 'features/AccountingDocument/accountDoucmentsSlice'
import { debounce } from 'lodash'
import { thunkGetAccountProfile } from 'app/authSlice'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
import PopupCancelDocument from 'features/AccountingDocument/component/PopupCancelDocument'
import { saveAs } from 'file-saver'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import PopupSendEmail from 'features/AccountingDocument/component/PopupSendEmail'
import { width } from '@mui/system'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import useSearchDebounce from 'hooks/useSearchDebounce'
import CustomFilter from '../../CustomFilter'
function AccountingDocuments({ tabActive }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [templatepaperRelease, setTemplatepaperRelease] = useState(null)
  const [templatepaperExits, setTemplatepaperExits] = useState(null)
  const [openModalAlert, setOpenModalAlert] = useState(null)
  const [visibleRemove, setVisibleRemove] = useState(null)
  const [visibleCancel, setVisibleCancel] = useState(null)
  const [visibleSendGmail, setVisibleSendGmail] = useState(null)
  const [messageAlert, setMessageAlert] = useState(null)
  const [dataSource, setDataSource] = useState(null)
  const [typeSign, setTypeSign] = useState()
  const [dataActive, setDataActive] = useState()
  const [formCancel] = Form.useForm()
  const [disableCancel, setDisableCancel] = useState(false)
  const [disableGmail, setDisableGmail] = useState(false)
  const [formGmail] = Form.useForm()
  const [auth, setAuth] = useState({})
  const { search, setSearch } = useState()
  const [filterParams, setFilterParams] = useState({})
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
    key: '',
  })

  const columns = [
    {
      title: t('accountingDocuments.numAd'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <div className="left-cell">{record?.numberDocument}</div>
      ),
    },
    {
      title: t('accountingDocuments.codetc'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => <div className="left-cell">{record?.code}</div>,
    },
    {
      title: t('accountingDocuments.sendEmail'),
      width: 150,
      className: 'center-header',
      render: (text, record) => {
        const constStatus = findObjectInArray(
          SelectOptions.listStatusGamil,
          'value',
          record.statusSendEmail,
        )
        return (
          <div className="left-cell">
            <SpanNotYetReleasedTd
              color={constStatus?.color}
              backGroundColor={constStatus?.backGroundColor}
            >
              {constStatus?.label}
            </SpanNotYetReleasedTd>
          </div>
        )
      },
    },
    {
      title: t('accountingDocuments.status'),
      width: 180,
      className: 'center-header',
      render: (text, record) => {
        const constStatus = findObjectInArray(
          SelectOptions.listStatusAccountingDocuments,
          'value',
          record.status,
        )
        return (
          <div className="left-cell">
            <SpanNotYetReleasedTd
              color={constStatus?.color}
              backGroundColor={constStatus?.backGroundColor}
            >
              {constStatus?.label}
            </SpanNotYetReleasedTd>
          </div>
        )
      },
    },
    {
      title: t('accountingDocuments.codennt'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <div className="left-cell">{record?.taxpayersCode}</div>
      ),
    },
    {
      title: t('taxpayer.taxpaperName'),
      width: 180,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record?.taxpayersName}</div>
      ),
    },
    // {
    //   title: t('taxpayer.employeeBirthDate'),
    //   width: 110,
    //   className: 'center-header',
    //   render: (text, record) => (
    //     <div className="left-cell">
    //       {record.employeeBirthDate
    //         ? convertDate(record.employeeBirthDate, 'DD/MM/YYYY')
    //         : ''}
    //     </div>
    //   ),
    // },
    {
      title: t('accountingDocuments.taxCode'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record.employeeTaxCode}</div>
      ),
    },
    {
      title: t('accountingDocuments.emailnnt'),
      width: 250,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={180}
          title={
            record?.employeeEmail
              ? record.employeeEmail
              : (record?.taxpayer?.employeeEmail ?? '')
          }
        >
          {record?.employeeEmail
            ? record.employeeEmail
            : (record?.taxpayer?.employeeEmail ?? '')}
        </EllipsisDiv>
      ),
    },
    {
      title: t('accountingDocuments.createdate'),
      width: 170,
      className: 'center-header',
      render: (text, record) => (
        <div className="center-cell">
          {record.createDate
            ? convertDate(record.createDate, 'DD/MM/YYYY HH:mm:ss')
            : ''}
        </div>
      ),
    },
    {
      title: t('accountingDocuments.time'),
      width: 130,
      className: 'center-header',
      render: (text, record) => (
        <div className="center-cell">
          {record.incomeStartMonth == record.lastIncomeMonth
            ? `${padNumberWithZeros(record?.incomeStartMonth, 2)}/${record?.incomeYear}`
            : `${padNumberWithZeros(record?.incomeStartMonth, 2)} - ${padNumberWithZeros(record?.lastIncomeMonth, 2)}/${record?.incomeYear}`}
        </div>
      ),
    },
    {
      title: t('accountingDocuments.kh'),
      width: 130,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="center-cell"
          width={130}
        >{`${record?.templatePaper?.symbolCode}/${record?.templatePaper?.symbolYear}/${record?.templatePaper?.symbolType}`}</EllipsisDiv>
      ),
    },
    {
      title: t('accountingDocuments.circular'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={150}
          title={record?.templatePaper?.circular}
        >
          {record?.templatePaper?.circular}
        </EllipsisDiv>
      ),
    },

    {
      title: t('accountingDocuments.address'),
      width: 300,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={300}
          title={
            record?.employeeAddress
              ? record?.employeeAddress
              : (record?.taxpayer?.employeeAddress ?? '')
          }
        >
          {record?.employeeAddress
            ? record?.employeeAddress
            : (record?.taxpayer?.employeeAddress ?? '')}
        </EllipsisDiv>
      ),
    },
    // {
    //   title: t('accountingDocuments.personCreate'),
    //   width: 150,
    //   className: 'center-header',
    //   render: (text, record) => (
    //     <EllipsisDiv className="left-cell" width={150} title={``}></EllipsisDiv>
    //   ),
    // },
    {
      title: t('accountingDocuments.reson'),
      width: 250,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv className="left-cell" width={250} title={record?.reson}>
          {record?.reson}
        </EllipsisDiv>
      ),
    },
    {
      title: t('accountingDocuments.note'),
      width: 300,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv className="left-cell" width={300} title={record?.note}>
          {record?.note}
        </EllipsisDiv>
      ),
    },
    {
      title: t('accountingDocuments.dateCancel'),
      width: 170,
      className: 'center-header',
      render: (text, record) => (
        <div className="center-cell">
          {record.cancelDate
            ? convertDate(record.cancelDate, 'DD/MM/YYYY HH:mm:ss')
            : ''}
        </div>
      ),
    },

    {
      title: '',
      width: 0,
      className: 'center-header',
      fixed: 'right',
      render: (text, record, index) => {
        let listFunction = [
          {
            key: 'photo',
            title: t('taxDeductionDocuments.in'),
            icon: (
              <PrinterOutlinedIcon
                display="inherit"
                onClick={() => {
                  exportFile('PDF', record.accounting_documentsId, 1)
                }}
              />
            ),
          },
          {
            key: 'remove',
            title: t('taxDeductionDocuments.remove'),
            icon: (
              <DeleteOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDataActive({
                    accounting_documentsId: record.accounting_documentsId,
                    code: record.code,
                  })
                  setVisibleRemove(true)
                }}
              />
            ),
          },
          {
            key: 'edit',
            title: t('taxDeductionDocuments.editt'),
            icon: (
              <FormOutlinedIcon
                display="inherit"
                onClick={() => {
                  navigate(
                    `/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/chungtu/lap-chung-tu?accounting_documents=${record.accounting_documentsId}`,
                  )
                }}
              />
            ),
          },
          {
            key: 'release',
            title: t('taxDeductionDocuments.pt'),
            icon: (
              <SendOutlinedIcon
                color="#007aff !important"
                display="inherit"
                onClick={() => {
                  sign(record?.accounting_documentsId)
                }}
              />
            ),
          },
          {
            key: 'cancelDocuments',
            title: t('accountingDocuments.hph'),
            icon: (
              <StopOutlinedIcon
                color="red !important"
                display="inherit"
                onClick={() => {
                  setDataActive({
                    accounting_documentsId: record.accounting_documentsId,
                    code: record.code,
                  })
                  setVisibleCancel(true)
                }}
              />
            ),
          },
          {
            key: 'copy',
            title: t('taxDeductionDocuments.copy'),
            icon: (
              <CopyOutlinedIcon
                display="inherit"
                onClick={() => {
                  navigate(
                    `/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/chungtu/lap-chung-tu?accounting_documents=${record.accounting_documentsId}&type=copy`,
                  )
                }}
              />
            ),
          },
          {
            key: 'gmail',
            title: t('accountingDocuments.sendEmail'),
            icon: (
              <MailOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDataActive({
                    accounting_documentsId: record.accounting_documentsId,
                    code: record.code,
                    taxpayersName: record.taxpayersName,
                    employeeEmail: record.employeeEmail,
                  })
                  setVisibleSendGmail(true)
                }}
              />
            ),
          },

          {
            key: 'export',
            title: t('taxDeductionDocuments.exportTemplate'),
            icon: (
              <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    overlay={createMenu(
                      record.accounting_documentsId,
                      record.companyId,
                    )}
                    placement="bottomLeft"
                  >
                    <DownloadOutlinedIcon
                      display="inherit"
                      onClick={() => {}}
                    />
                  </Dropdown>
                </Space>
              </Space>
            ),
          },
        ]
        if (record.status === 1) {
          listFunction = removeItemByKeys(
            [...listFunction],
            ['edit', 'remove', 'release'],
          )
        } else if (record.status === 2) {
          listFunction = removeItemByKeys(
            [...listFunction],
            ['edit', 'remove', 'release', 'cancelDocuments'],
          )
        } else if (record.status === 0) {
          listFunction = removeItemByKeys(
            [...listFunction],
            ['cancelDocuments'],
          )
        }
        return (
          <ContainerFunction className="left-cell">
            <DivListFunction className="listFuntionFlex">
              {listFunction.map((item, indexl) => (
                <DivFunction
                  title={item.title}
                  key={indexl}
                  right={`${indexl * 40}px`}
                >
                  {item.icon}
                </DivFunction>
              ))}
            </DivListFunction>
          </ContainerFunction>
        )
      },
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(thunkGetAccountProfile())
      if (data?.payload) {
        const dataCompany = await dispatch(
          thunkGetCompanyProfile({
            companyId: data?.payload?.company?.companyId,
          }),
        )
        setTypeSign(dataCompany?.payload?.activeSigningMethod)
      }
      setAuth(data?.payload?.company)
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (tabActive == 2) {
      getTemplatepaperRelease()
      findDataSource()
    }
  }, [tabActive])

  useEffect(() => {
    findDataSource()
  }, [
    panningDataSource.page,
    panningDataSource.limit,
    panningDataSource.key,
    filterParams?.status,
    filterParams?.startDate,
    filterParams?.endDate,
  ])

  const getTemplatepaperRelease = async () => {
    const result = unwrapResult(
      await dispatch(
        thunkFindByTempapeRreleaseTaxDeductionDocuments({
          year: dayjs().year(),
        }),
      ),
    )
    if (result?.message === 'success') {
      setTemplatepaperRelease(result?.data)
      setTemplatepaperExits(result?.dataExits)
    } else {
      setTemplatepaperRelease(null)
      setTemplatepaperExits(null)
    }
  }

  const createMenu = (accounting_documentsId) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          exportFile('PDF', accounting_documentsId)
        }}
      >
        <b>
          <FilePdfOutlinedIcon
            style={{
              color: 'red',
              marginRight: '10px',
              position: 'relative',
              top: '-2px',
              display: 'inline',
            }}
          />
          PDF
        </b>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          exportFile('XML', accounting_documentsId)
        }}
      >
        <b>
          <FileExcelOutlinedIcon
            style={{
              color: '#304ffd',
              marginRight: '10px',
              position: 'relative',
              top: '-2px',
              display: 'inline',
            }}
          />
          XML
        </b>
      </Menu.Item>
    </Menu>
  )

  const findDataSource = async () => {
    try {
      if (filterParams.startDate) {
        const data = unwrapResult(
          await dispatch(
            thunkFindAccountingDocument({
              ...filterParams,
              ...panningDataSource,
            }),
          ),
        )
        setDataSource(data.items)
        setPanningDataSource({
          ...panningDataSource,
          totalItems: data.totalItems,
          totalPages: data.totalPages,
        })
      }
    } catch (error) {}
  }

  const sign = async (accounting_documentsId) => {
    try {
      const result = unwrapResult(
        await dispatch(
          thunkSignHSMAccountingDocument({ accounting_documentsId, typeSign }),
        ),
      )
      if (result?.message == 'success') {
        if (typeSign == DIGITAL_SIGN_METHOD.HSM) {
          ToastHelper.showSuccess(t('accountingDocuments.sign'))
          resetResouce()
          findDataSource()
        } else {
          let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
          let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${auth.taxCode},${accounting_documentsId},AccountingDocuments`
          console.log('urlSchema', urlSchema)
          Utils.openToolSignAccountingDocuments(urlSchema, accessToken)
        }
      } else {
        ToastHelper.showError(result?.data)
      }
    } catch (error) {}
  }

  const remove = async () => {
    try {
      const result = unwrapResult(
        await dispatch(thunkRemoveAccountingDocument(dataActive)),
      )
      if (result?.message == 'success') {
        ToastHelper.showSuccess(t('accountingDocuments.removeSuc'))
        setVisibleRemove(false)
        findDataSource()
      } else {
        ToastHelper.showError(result?.data)
      }
    } catch (error) {}
  }

  const cancel = async (data) => {
    try {
      const result = unwrapResult(
        await dispatch(thunkCancelAccountingDocument(data)),
      )
      setDisableCancel(false)
      if (result?.message == 'success') {
        ToastHelper.showSuccess(t('accountingDocuments.cancelSuc'))
        setVisibleCancel(false)
        findDataSource()
      } else {
        ToastHelper.showError(result?.data)
      }
    } catch (error) {}
  }

  const exportFile = async (typeFile, accounting_documentsId, view) => {
    const dataFile = await dispatch(
      thunkExportAccountingDocument({
        typeFile,
        accounting_documentsId,
      }),
    )
    if (dataFile?.payload?.data && dataFile.payload.fileName) {
      const { data, fileName } = dataFile.payload
      if (data.type === 'Buffer') {
        const byteArray = new Uint8Array(data.data)
        const blob = new Blob([byteArray], {
          type: typeFile == 'XML' ? 'application/xml' : 'application/pdf',
        })
        if (view) {
          const url = URL.createObjectURL(blob)
          window.open(url, '_blank')
        } else {
          saveAs(blob, fileName)
        }
      } else {
        ToastHelper.showError('Invalid buffer data')
      }
    }
  }

  const sendEmail = async (values) => {
    try {
      let data = {
        ...values,
        ccEmails: values?.ccEmails
          ? values.ccEmails.split(';').map((email) => email.trim())
          : [],
        bccEmails: values?.bccEmails
          ? values.bccEmails.split(';').map((email) => email.trim())
          : [],
        accounting_documentsId: dataActive?.accounting_documentsId,
      }
      const result = unwrapResult(
        await dispatch(thunkSendEmailAccountingDocument(data)),
      )
      if (result?.message == 'success') {
        ToastHelper.showSuccess(t('accountingDocuments.sendsus'))
        setVisibleSendGmail(false)
        findDataSource()
      } else {
        ToastHelper.showError(result?.data)
        setDisableGmail(false)
        // setVisibleSendGmail(false)
      }
    } catch (error) {}
  }

  const resetResouce = async () => {
    return await dispatch(thunkGetAccountProfile())
  }

  const debouncedSetFormState = useCallback(
    debounce((keyValue, value) => {
      // Gọi API tại đây với keyValue và value
      setPanningDataSource((prevPanningDataSource) => ({
        ...prevPanningDataSource,
        [keyValue]: value,
      }))
    }, 300), // Thời gian chờ sau khi kết thúc nhập liệu, ví dụ: 500ms
    [], // Empty dependency array means this callback will only be created once
  )

  const handleChange = (key, value) => {
    // Xóa timeout hiện tại để tránh gọi API nếu người dùng vẫn đang nhập
    clearTimeout(timeoutId)

    // Thiết lập timeout mới với debounce
    timeoutId = setTimeout(() => {
      debouncedSetFormState(key, value)
    }, 500) // Đợi 500ms sau khi kết thúc nhập liệu
  }

  let timeoutId = null // Biến lưu trữ timeout ID

  return (
    <ContainerTable>
      <div className="pt-2 pb-5 row">
        {/* <div className="col-sm-3">
          <SpaceCustom.Compact>
            <InputCustom
              onChange={(e) => {
                handleChange('key', e.target.value)
              }}
              minWidth="320px"
              addonBefore={
                <SearchOutlined
                  onClick={() => {
                    findDataSource()
                  }}
                />
              }
              placeholder={t('taxpayer.keySearch')}
              allowClear
            />
          </SpaceCustom.Compact>
        </div>
        <div className="col-sm-2">
          <SelectCustom
            style={{ width: '100%' }}
            placeholder={t('accountingDocuments.chonseStatus')}
            allowClear
            onChange={(value) => {
              setPanningDataSource({ ...panningDataSource, status: value })
            }}
            options={SelectOptions.listStatusAccountingDocuments}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
          ></SelectCustom>
        </div> */}
        <div className="col-sm-10">
          <Space wrap className="max-w-100" id="filter-content-wrapper">
            <CustomAntInput
              type="search"
              borderRadius="sm"
              width={300}
              inputProps={{
                placeholder: t('taxpayer.keySearch'),
                value: search,
                onChange: (e) => {
                  handleChange('key', e.target.value)
                },
              }}
            />
            <CustomFilter
              filterParams={filterParams}
              setFilterParams={(value) => setFilterParams(value)}
            />
          </Space>
        </div>
        <DivRight className="col-sm-2">
          <ButtonSave
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              if (templatepaperRelease) {
                navigate(
                  `/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/chungtu/lap-chung-tu?templatePaper=${templatepaperRelease.templatePaperId}`,
                )
              } else {
                setOpenModalAlert(true)
                if (templatepaperExits) {
                  setMessageAlert(
                    t('accountingDocuments.notTemplateRealead', {
                      value: dayjs().year(),
                    }),
                  )
                } else {
                  setMessageAlert(
                    t('accountingDocuments.notSignTemplateRealead', {
                      value: dayjs().year(),
                    }),
                  )
                }
              }
            }}
          >
            {t('accountingDocuments.titleAdd')}
          </ButtonSave>
        </DivRight>
        <div className="pt-2" style={{ color: '#000000', marginLeft: '4px' }}>
          {t('taxpayer.tong')}:{' '}
          <b>
            {panningDataSource?.totalItems ? panningDataSource.totalItems : 0}
          </b>{' '}
          {t('taxpayer.ct')}
        </div>
      </div>
      <TableCustom
        scroll={{ x: 1200 }}
        dataSource={dataSource}
        columns={columns}
        locale={{
          emptyText: <Empty description={t('taxDeduction.noData')} />,
        }}
        marginRight={'0px'}
        marginLeft={'0px'}
        pagination={
          panningDataSource.totalPages > 1
            ? {
                current: panningDataSource.page,
                pageSize: panningDataSource.limit,
                total: panningDataSource.totalItems,
              }
            : false
        }
        onChange={(pagination) => {
          setPanningDataSource({
            ...panningDataSource,
            ...pagination,
            page: pagination.current,
            limit: pagination.pageSize,
          })
        }}
      />
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeduction.closeModal')}
        titlePrimary={t('accountingDocuments.viewTemplate')}
        openModalAlert={openModalAlert}
        titleAlert={t('accountingDocuments.notification')}
        messageAlert={messageAlert}
        setOpenModalAlert={(value) => {
          setOpenModalAlert(value)
        }}
        onClickPrimary={() => {
          navigate('/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau')
        }}
      />
      {/* remove */}
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.remove')}
        openModalAlert={visibleRemove}
        titleAlert={t('accountingDocuments.removennt')}
        setOpenModalAlert={(value) => {
          setVisibleRemove(value)
        }}
        onClickPrimary={() => {
          remove()
        }}
        colorButtonSave={'#ffffff'}
        backGroundButtonSave={'#e61d1d'}
        backGroundButtonSaveHover={'#cc0202!important'}
        messageActive1={t('accountingDocuments.meremove1')}
        messageActive2={t('accountingDocuments.meremove2')}
        nameActive={dataActive?.code}
      />
      {/* cancel document */}
      <PopupCancelDocument
        visible={visibleCancel}
        setVisible={(value) => {
          setVisibleCancel(value)
        }}
        form={formCancel}
        submit={(value) => {
          cancel({
            ...value,
            accounting_documentsId: dataActive?.accounting_documentsId,
          })
        }}
        disableSave={disableCancel}
        setDisableSave={(value) => {
          setDisableCancel(value)
        }}
      />
      {/* send document */}
      <PopupSendEmail
        visible={visibleSendGmail}
        setVisible={(value) => {
          setVisibleSendGmail(value)
        }}
        form={formGmail}
        disableSave={disableGmail}
        setDisableSave={(value) => {
          setDisableGmail(value)
        }}
        submit={(data) => {
          sendEmail(data)
        }}
        dataActive={dataActive}
      />
    </ContainerTable>
  )
}
export default AccountingDocuments
