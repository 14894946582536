// import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import ImportTicketCard from 'features/Ticket/components/ImportTicketCard'
import ImportTicketFooter from 'features/Ticket/components/ImportTicketFooter'
import _ from 'lodash'
import './styles.scss'
import { useAppSelector } from 'hooks/useRedux'
import { useDispatch } from 'react-redux'
import {
  setCheckRowsInfo,
  setCurrentStep,
  setSelectedFile,
} from './importTicketSlice'
import {
  ALPHABET_CHARACTERS,
  IMPORT_TICKET_STEPS,
  TICKET_UPLOAD_GUIDES,
  TICKET_UPLOAD_HEADER_COLUMNS,
} from 'general/constants/AppConstants'
import ToastHelper from 'general/helpers/ToastHelper'
import { setAppSpinning } from 'app/appSlice'
import ExcelJs from 'exceljs'
import useGetTicketSerial from 'hooks/Queries/useGetTicketSerial'
import { filterColumnsBaseOnTicketTemplate } from 'general/helpers/ImportTicketHelper'
import ticketDraftApi from 'api/ticektDraftApi'
import Utils from 'general/utils/Utils'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import { useEffect, useMemo, useState } from 'react'

function ImportTicket({ classify }) {
  // MARK --- Parmas: ---
  const dispatch = useDispatch()
  const [numRowValid, setNumRowValid] = useState(false)
  // const navigate = useNavigate()
  const {
    currentStep,
    excelData,
    selectedSerial,
    selectedSheetName,
    checkRowsInfo,
  } = useAppSelector((state) => state.importTicket)

  const { currency } = useAppSelector((state) => state.systemConfig)
  const { data: ticketSerials } = useGetTicketSerial({
    includeAllOption: false,
    disabled: false,
    classify,
  })

  const validRowNum = useMemo(
    () =>
      checkRowsInfo?.rowWithUploadErrors?.filter(
        ({ uploadErrors }) => !uploadErrors,
      )?.length,
    [checkRowsInfo?.rowWithUploadErrors],
  )
  const rowNum = useMemo(
    () => checkRowsInfo?.rowWithUploadErrors?.length,
    [checkRowsInfo?.rowWithUploadErrors],
  )

  // reset dữ liệu khi bắt đầu hoặc thoát khỏi trang
  useEffect(() => {
    handleConfirmCheckValidModal()
    return () => {
      handleConfirmCheckValidModal()
    }
  }, [])

  const handleIncreaseStep = () => {
    if (currentStep >= IMPORT_TICKET_STEPS.length) return

    dispatch(setCurrentStep(currentStep + 1))
  }

  const handleDecreaseStep = () => {
    if (currentStep <= 1) return

    dispatch(setCurrentStep(currentStep - 1))
  }

  const handleCheckRowsData = async ({ isUpLoad = false }) => {
    if (!excelData?.rows?.length) {
      ToastHelper.showError('Dữ liệu kiểm tra bị trống!')
      return
    }

    dispatch(setAppSpinning(true))
    try {
      let res = await ticketDraftApi.checkUpload({
        sheetName: selectedSheetName,
        rows: excelData.rows,
      })
      dispatch(setAppSpinning(false))
      if (res?.result == 'success') {
        let { sheetName, rows: rowWithUploadErrors } = res
        dispatch(setCheckRowsInfo({ sheetName, rowWithUploadErrors }))
        if (isUpLoad) {
          const rowValidCount = rowWithUploadErrors?.filter(
            ({ uploadErrors }) => !uploadErrors,
          )?.length
          const rowLength = rowWithUploadErrors?.length
          if (rowValidCount / rowLength !== 1) {
            setNumRowValid(true)
            return false
          }
          return true
        }
      }
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
      return false
    }
  }

  const handleDownloadFileCheck = async () => {
    if (!checkRowsInfo) return

    try {
      let serial = ticketSerials.find(({ value }) => value == selectedSerial)
      if (!serial) {
        alert('Lỗi khi tìm kiếm serial')
        return
      }

      const fileName = 'Kết quả kiểm tra dữ liệu ' + serial.name + '.xlsx'

      const wb = new ExcelJs.Workbook()
      const ws = wb.addWorksheet(checkRowsInfo.sheetName)

      const commonStyle = {
        name: 'Times New Roman',
        family: 4,
        size: 13,
      }

      const commonBorder = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      }

      const errorBorder = {
        top: { style: 'medium', color: { argb: 'FF0000' } },
        left: { style: 'medium', color: { argb: 'FF0000' } },
        bottom: { style: 'medium', color: { argb: 'FF0000' } },
        right: { style: 'medium', color: { argb: 'FF0000' } },
      }

      /* gen excel columns */
      let excelColumns = TICKET_UPLOAD_HEADER_COLUMNS.filter(
        ({ onlyWeb }) => onlyWeb !== true,
      )
      excelColumns = filterColumnsBaseOnTicketTemplate({
        baseColumns: excelColumns,
        serial,
      })
      excelColumns.push({
        header: 'Chi tiết lỗi',
        key: 'chitietloi',
        width: 30,
        description: 'Chi tiết lỗi',
        mappingKeywords: 'Chi tiết lỗi',
      })
      // let excelColumns = rowWithUploadErrors

      let columnsLength = excelColumns.length

      // uploadGuides
      ws.mergeCells(`A1:${ALPHABET_CHARACTERS[columnsLength - 1]}1`)
      ws.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '99CCFF' },
      }
      ws.getCell('A1').value = TICKET_UPLOAD_GUIDES.title
      ws.getCell('A1').font = {
        ...commonStyle,
        size: 16,
        bold: true,
      }

      ws.mergeCells(`A2:${ALPHABET_CHARACTERS[columnsLength - 1]}2`)
      ws.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCC99' },
      }
      ws.getCell('A2').value = TICKET_UPLOAD_GUIDES.subtitle
      ws.getCell('A2').font = {
        ...commonStyle,
        color: { argb: 'FF0000' },
      }

      let startIndex = 3
      TICKET_UPLOAD_GUIDES.guides.forEach((guide) => {
        ws.mergeCells(
          `A${startIndex}:${
            ALPHABET_CHARACTERS[columnsLength - 1]
          }${startIndex}`,
        )
        ws.getCell(`A${startIndex}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCC99' },
        }
        ws.getCell(`A${startIndex}`).value = guide
        ws.getCell(`A${startIndex}`).font = {
          ...commonStyle,
        }
        startIndex++
      })

      // sampleData
      /* header */
      startIndex = 8
      ws.getRow(startIndex).font = {
        ...commonStyle,
        bold: true,
      }
      ws.getRow(startIndex).values = excelColumns.map(({ header }) => header)
      excelColumns.forEach((_, index) => {
        let cellName = `${ALPHABET_CHARACTERS[index]}${startIndex}}`
        ws.getCell(cellName).border = commonBorder
        ws.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'CCCCFF' },
        }
        ws.getCell(cellName).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        }
      })
      ws.columns = excelColumns.map(({ key, width }) => {
        return { key, width }
      })

      /* data */
      startIndex = 9
      ws.addRows(
        checkRowsInfo?.rowWithUploadErrors.map((item) => ({
          ...item,
          chitietloi: item.uploadErrors
            ?.map(({ message }) => message)
            ?.join(';'),
        })),
      )
      ws.eachRow((row, rowNumber) => {
        if (rowNumber < startIndex) return

        row.eachCell((cell, colNum) => {
          let cellKey = excelColumns[colNum - 1]?.key
          let isErrorCell =
            !!checkRowsInfo?.rowWithUploadErrors[
              rowNumber - startIndex
            ].uploadErrors?.find(({ key }) => key == cellKey) ||
            cellKey == 'chitietloi'

          cell.font = commonStyle
          if (isErrorCell) {
            cell.border = errorBorder
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: false,
            }
          } else {
            cell.border = commonBorder
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            }
          }
        })
      })

      await wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = fileName
        anchor.click()
        window.URL.revokeObjectURL(url)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleUploadRows = async () => {
    if (!excelData?.rows?.length) {
      ToastHelper.showError('Không có dữ liệu!')
      return
    }
    const checkRowDataResult = await handleCheckRowsData({ isUpLoad: true })
    if (!checkRowDataResult) return
    // kiểm tra trạng thái hợp lệ

    let invoiceTemplateId = ticketSerials.find(
      ({ value }) => value == selectedSerial,
    )?.invoiceTemplateId
    let rows = excelData.rows.map((item) => ({
      ...item,
      totalPriceVnese: Utils.numberToWords(
        item.tongtien,
        'vi',
        '',
        'đồng', //endSymbol
        'phẩy', // afterdecimal
        '',
        '',
        currency?.zeroInten,
        currency?.zeroInThousand,
        currency?.evenDisplay,
      ),
    }))

    dispatch(setAppSpinning(true))
    try {
      let params = { invoiceTemplateId, rows, classify }
      let res = await ticketDraftApi.upload(params)
      dispatch(setAppSpinning(false))
      let { metadata: resRows } = res

      handleConfirmCheckValidModal()
      ToastHelper.showSuccess(`Đã xuất thành công ${resRows.length} vé`)
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  const handleConfirmCheckValidModal = () => {
    dispatch(setCurrentStep(1))
    dispatch(setSelectedFile(null))
    dispatch(setCheckRowsInfo(null))
  }

  return (
    <>
      <div className="import-ticket d-flex p-3 h-100 flex-column">
        <div className="import-ticket-header">Nhập khẩu vé</div>
        <ImportTicketCard ticketSerials={ticketSerials} />
        <ImportTicketFooter
          currentStep={currentStep}
          handleIncreaseStep={handleIncreaseStep}
          handleDecreaseStep={handleDecreaseStep}
          handleCheckRowsData={handleCheckRowsData}
          handleDownloadFileCheck={handleDownloadFileCheck}
          handleUploadRows={handleUploadRows}
        />
      </div>
      {/* show, setShow, header, content, handleConfirm, runWhenClosedFunc */}
      <ModalConfirm
        show={numRowValid}
        setShow={(bool) => setNumRowValid(bool)}
        header={'Nhập khẩu vé'}
        content={`Có ${validRowNum ?? 0} trên tổng số ${rowNum ?? 0} vé hợp lệ. Bạn có muốn bỏ qua các vé này và thực hiện nhập khẩu các vé còn lại không?`}
        handleConfirm={handleConfirmCheckValidModal}
      />
    </>
  )
}

export default ImportTicket
